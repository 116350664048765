import md5 from "md5";

// 签名
export function generateSign(data, _appSecret) {
    let sortData = SortObject(data);
    let keys = Object.keys(sortData);
    let strSignData = "";
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let val = sortData[key];
        if (val != null && val !== "") {
            if (typeof val === 'object') {
                strSignData = strSignData + key + JSON.stringify(val);
            } else {
                strSignData = strSignData + key + val;
            }
        }
    }
    strSignData = _appSecret + strSignData + _appSecret;
    let sign = md5(strSignData);
    return {strSignData, sign};
}

function SortObject(object) {
    if (typeof object != "object" || object instanceof Array)
        // Not to sort the array
        return object;
    let keys = Object.keys(object);
    keys.sort();
    let newObject = {};
    for (let i = 0; i < keys.length; i++) {
        newObject[keys[i]] = SortObject(object[keys[i]]);
    }
    return newObject;
}
