<template>
    <div class="api">
        <div class="left-menu" :class="{ 'fix-menu': fixMenu }">
            <a class="block-title" @click="scrollId('title1')">请求结构</a>
            <a class="s-block-title" @click="scrollId('title1-1')">协议</a>
            <a class="s-block-title" @click="scrollId('title1-2')">域名</a>
            <a class="s-block-title" @click="scrollId('title1-3')">请求方式</a>
            <a class="s-block-title" @click="scrollId('title1-4')">请求头</a>
            <a class="s-block-title" @click="scrollId('title1-5')">响应参数</a>

            <a class="block-title" @click="scrollId('title2')">公共参数</a>
            <a class="s-block-title" @click="scrollId('title2-1')">公共请求参数</a>
            <a class="s-block-title" @click="scrollId('title2-2')">公共响应参数</a>

            <a class="block-title" @click="scrollId('title3')">签名规则</a>

            <a class="block-title" @click="scrollId('title4')">接口列表</a>
            <a class="s-block-title" @click="scrollId('title4-14')">绑定打印机</a>
            <a class="s-block-title" @click="scrollId('title4-12')">标签模板打印-间隙纸</a>
            <a class="s-block-title" @click="scrollId('title4-11')">小票模板打印-间隙纸</a>
            <a class="s-block-title" @click="scrollId('title4-13')">小票模板打印-连续纸</a>
            <a class="s-block-title" @click="scrollId('title4-10')">自定义xml打印-间隙纸</a>
            <a class="s-block-title" @click="scrollId('title4-8')">自定义xml打印-连续纸</a>
            <a class="s-block-title" @click="scrollId('title4-2')">TSPL/ESC指令打印</a>
          <a class="s-block-title" @click="scrollId('title4-4')">ESC指令打印</a>
            <a class="s-block-title" @click="scrollId('title4-3')">打印任务结果查询</a>
            <!-- <a class="s-block-title" href="#title4-5">小票机logo上传</a> -->
<!--            <a class="s-block-title" @click="scrollId('title4-6')">打印机状态查询</a>-->
            <a class="s-block-title" @click="scrollId('title4-9')">打印机状态查询</a>
<!--            <a class="s-block-title" @click="scrollId('title4-7')">查询打印机是否存在</a>-->
            <a class="s-block-title" @click="scrollId('title4-15')">错误码</a>
            <!-- <a class="s-block-title" href="#title4-8">查询打印机基础信息</a> -->

<!--            <a class="block-title" @click="scrollId('title5')">SDK列表</a>-->
        </div>
        <div class="content">
            <h1 id="title1">对接流程图</h1>
            <el-image
                style="width: 100%;"
                src="https://kuaimai-public.oss-cn-shanghai.aliyuncs.com/public/云打印流程图.png"
                :preview-src-list="['https://kuaimai-public.oss-cn-shanghai.aliyuncs.com/public/云打印流程图.png']">
            </el-image>
            <h1 id="title1">请求结构</h1>
            <h2 id="title1-1">协议</h2>
            <p>http或https</p>
            <h2 id="title1-2">域名</h2>
            <p>cloud.kuaimai.com</p>
            <h2 id="title1-3">请求方式</h2>
            <p>POST</p>
            <h2 id="title1-4">请求头</h2>
            <p>Content-Type:application/json</p>
            <h2 id="title1-5">响应参数</h2>
            <p>json</p>

            <h1 id="title2">公共参数</h1>
            <h2 id="title2-1">公共请求参数</h2>
            <table>
                <tr>
                    <th>参数名</th>
                    <th>含义</th>
                    <th>类型</th>
                    <th>必传</th>
                    <th>示例</th>
                </tr>
                <tr>
                    <td>appId</td>
                    <td>应用ID</td>
                    <td>String</td>
                    <td>是</td>
                    <td>"1100"</td>
                </tr>
                <tr>
                    <td>timestamp</td>
                    <td>当前时间</td>
                    <td>String</td>
                    <td>是</td>
                    <td>"2020-08-14 15:29:29"</td>
                </tr>
                <tr>
                    <td>sign</td>
                    <td>签名值</td>
                    <td>String</td>
                    <td>是</td>
                    <td>"ff9d58e9abcf50dcc423fad782636c21"</td>
                </tr>
            </table>
            <h2 id="title2-2">公共响应参数</h2>
            <table>
                <tr>
                    <th>参数名</th>
                    <th>含义</th>
                    <th>类型</th>
                    <th>示例</th>
                </tr>
                <tr>
                    <td>status</td>
                    <td>响应结果</td>
                    <td>boolean</td>
                    <td>true为成功，false为失败</td>
                </tr>
                <tr>
                    <td>data</td>
                    <td>响应数据</td>
                    <td>object</td>
                    <td>具体接口的返回数据</td>
                </tr>
                <tr>
                    <td>message</td>
                    <td>响应说明</td>
                    <td>String</td>
                    <td>status为false时，返回信息</td>
                </tr>
              <tr>
                <td>code</td>
                <td>code码</td>
                <td>Number</td>
                <td>详情见最下方code码说明</td>
              </tr>
            </table>

            <h1 id="title3">签名规则</h1>
            <p>将所有参数（sign除外，并且剔除value为空的参数），对参数的key进行ascii码升序排序，并组成如下字符串</p>
            <pre class="code">
x={appId}{appId_val}{param1}{param1_val}{param2}{param2_val}...{timestamp}{timestamp_val}...</pre
            >
            <p>例如:x=appId123456snKM118MWtimestamp2020-10-10 15:29:29</p>
            <p>将appSecret（接入方在快麦云的密钥）在上面的排序好的字符串前后都追加上，然后md5加密,md5之后的值取32位小写</p>
            <pre class="code">sign=MD5({secret}x{secret})</pre>
            <p>例如:假如你的secret是abc;sign=MD5(abcxabc),x替换为上方举例中的x就为:sign=MD5(abcappId123456snKM118MWtimestamp2020-10-10
                15:29:29abc)</p>
            <p style="color: #e96900">
                备注：appId 和 appSecret 可通过页面右上角 注册\登录 按钮获取；上方的大括号{}为占位符,其本身不参与字符串的拼接
            </p>
            <h2>JAVA签名示例代码:</h2>
            <pre>
                <code class="language-java">
public static String createSign(Map&lt;String,Object&gt; paramMap, String secret) {
    // 第一步：检查参数是否已经排序
    String[] keys = paramMap.keySet().toArray(new String[0]);
    Arrays.sort(keys);
    // 第二步：把所有参数名和参数值串在一起
    StringBuilder query = new StringBuilder();
    query.append(secret);
    for (String key : keys) {
        if (key != null && paramMap.get(key) != null) {
            String keyString = "" + key;
            String valueString = "" + paramMap.get(key);
            if (StringUtils.isNotBlank(keyString) && StringUtils.isNotBlank(valueString)) {
                query.append(keyString).append(valueString);
            }
        }
    }
    query.append(secret);
    String sign;
    // 第三步：使用MD5加密,取32位小写
    return DigestUtil.md5Hex(query.toString());
}
                </code>
            </pre>

            <h1 id="title4">接口列表</h1>

            <h2 id="title4-14">绑定打印机</h2>
            <p>url:/api/cloud/device/bindDevice</p>
           <p>公共参数:</p>
          <table>
            <tr>
              <th>参数名</th>
              <th>含义</th>
              <th>类型</th>
              <th>必传</th>
              <th>示例</th>
            </tr>
            <tr>
              <td>appId</td>
              <td>应用ID</td>
              <td>String</td>
              <td>是</td>
              <td>"1100"</td>
            </tr>
            <tr>
              <td>timestamp</td>
              <td>当前时间</td>
              <td>String</td>
              <td>是</td>
              <td>"2020-08-14 15:29:29"</td>
            </tr>
            <tr>
              <td>sign</td>
              <td>签名值</td>
              <td>String</td>
              <td>是</td>
              <td>"ff9d58e9abcf50dcc423fad782636c21"</td>
            </tr>
          </table>
            <p>私有参数：</p>
            <table>
                <tr>
                    <th>参数名</th>
                    <th>含义</th>
                    <th>类型</th>
                    <th>必传</th>
                    <th>示例与说明</th>
                </tr>
                <tr>
                    <td>sn</td>
                    <td>序列号</td>
                    <td>String</td>
                    <td>是</td>
                    <td>"km789838899"</td>
                </tr>
                <tr>
                    <td>deviceKey</td>
                    <td>设备密钥</td>
                    <td>String</td>
                    <td>否</td>
                    <td>如果设备机身贴有密钥，该参数就是必传</td>
                </tr>
                <tr>
                    <td>bindName</td>
                    <td>绑定名称</td>
                    <td>String</td>
                    <td>否</td>
                    <td>应用方的绑定名称，例如用户名/用户id</td>
                </tr>
                <tr>
                    <td>noteName</td>
                    <td>备注名称</td>
                    <td>String</td>
                    <td>否</td>
                    <td>备注名称，例如杭州分店一</td>
                </tr>
            </table>
            <p>入参完整示例：</p>
            <pre class="code">
{
   "sn":"KM118933",
   "deviceKey": '123456',
   "bindName": '18922223984',
   "noteName": '杭州分店',
   "appId":"1100",
    "timestamp":"2020-08-24 11:11:59",
    "sign":"testsignvalue"
}</pre>
            <p>响应参数</p>
            <table>
                <tr>
                    <th>参数名</th>
                    <th>含义</th>
                    <th>类型</th>
                    <th>示例</th>
                </tr>
                <tr>
                    <td>status</td>
                    <td>响应结果</td>
                    <td>boolean</td>
                    <td>true为成功，false为失败</td>
                </tr>
                <tr>
                    <td>data</td>
                    <td>响应数据</td>
                    <td>object</td>
                    <td>接口的返回数据</td>
                </tr>
                <tr>
                    <td>message</td>
                    <td>响应说明</td>
                    <td>String</td>
                    <td>status为false时，返回信息</td>
                </tr>
                <tr>
                  <td>code</td>
                  <td>code码</td>
                  <td>Number</td>
                  <td>详情见最下方code码说明</td>
                </tr>
            </table>

            <h2 id="title4-12">标签模板打印-间隙纸 适用机型:KM118DW,KM118MW,KMSX320,KME20W</h2>
            <p>url:/api/cloud/print/tsplTemplatePrint</p>
          <p>公共参数:</p>
          <table>
            <tr>
              <th>参数名</th>
              <th>含义</th>
              <th>类型</th>
              <th>必传</th>
              <th>示例</th>
            </tr>
            <tr>
              <td>appId</td>
              <td>应用ID</td>
              <td>String</td>
              <td>是</td>
              <td>"1100"</td>
            </tr>
            <tr>
              <td>timestamp</td>
              <td>当前时间</td>
              <td>String</td>
              <td>是</td>
              <td>"2020-08-14 15:29:29"</td>
            </tr>
            <tr>
              <td>sign</td>
              <td>签名值</td>
              <td>String</td>
              <td>是</td>
              <td>"ff9d58e9abcf50dcc423fad782636c21"</td>
            </tr>
          </table>
          <p>私有参数：</p>
            <table>
                <tr>
                    <th>参数名</th>
                    <th>含义</th>
                    <th>类型</th>
                    <th>必传</th>
                    <th>示例与说明</th>
                </tr>
                <tr>
                    <td>sn</td>
                    <td>序列号</td>
                    <td>String</td>
                    <td>是</td>
                    <td>"km789838899"</td>
                </tr>
                <tr>
                    <td>templateId</td>
                    <td>模板id</td>
                    <td>Number</td>
                    <td>是</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>renderDataArray</td>
                    <td>模板动态渲染数据(支持一次渲染多份数据)</td>
                    <td>String</td>
                    <td>是</td>
                    <td>
                        "[{ \"bindTable1\":[ { \"bindkey1\":\"value1\" } ], \"bindTable2\":[ { \"bindkey1\":\"value1\",
                        \"bindkey2\":\"value2\" } ] },{ \"bindTable1\":[ { \"bindkey1\":\"value11\" } ],
                        \"bindTable2\":[ { \"bindkey1\":\"value11\", \"bindkey2\":\"value21\" } ] }]"
                    </td>
                </tr>
                <tr>
                    <td>printTimes</td>
                    <td>打印份数</td>
                    <td>Number</td>
                    <td>否</td>
                    <td>1</td>
                </tr>
            </table>
            <p style="color: #e96900">说明：参数renderDataArray为JSONArray字符串，该JSONArray里的元素类型为JSONObject,JSONObject里的key对应的value值是JSONArray数据结构,具体参考见示例;示例中的bindTable和bindKey对应了字段管理里的表名和字段名;如果有图片传图片的base64数据,图片数据在50KB以内最佳</p>
            <p>入参完整示例：</p>
            <pre class="code">
{
   "sn":"KM118933",
   "templateId": 11,
   "renderDataArray":"[{
        \"bindTable1\":[
            {
                \"bindkey1\":\"value1\"
            }
        ],
        \"bindTable2\":[
            {
                \"bindkey1\":\"value1\",
                \"bindkey2\":\"value2\"
            }
        ]
    },{
              \"bindTable1\":[
                  {
                      \"bindkey1\":\"value11\"
                  }
              ],
              \"bindTable2\":[
                  {
                      \"bindkey1\":\"value11\",
                      \"bindkey2\":\"value22\"
                  }
              ]
          }]",
   "appId":"1100",
    "timestamp":"2020-08-24 11:11:59",
    "sign":"testsignvalue"
}</pre>
          <p>响应参数：</p>
          <table>
            <tr>
              <th>参数名</th>
              <th>含义</th>
              <th>类型</th>
              <th>示例与说明</th>
            </tr>
            <tr>
              <td>status</td>
              <td>响应结果</td>
              <td>Boolean</td>
              <td>true为成功，false为失败</td>
            </tr>
            <tr>
              <td>data</td>
              <td>响应数据</td>
              <td>Object</td>
              <td>具体接口的返回数据</td>
            </tr>
            <tr>
              <td>-jobIds</td>
              <td></td>
              <td>Array</td>
              <td>打印任务id</td>
            </tr>
            <tr>
              <td>message</td>
              <td>响应说明</td>
              <td>String</td>
              <td>status为false时，返回信息</td>
            </tr>
            <tr>
              <td>code</td>
              <td>code码</td>
              <td>Number</td>
              <td>详情见最下方code码说明</td>
            </tr>
          </table>
          <p>出参完整示例：</p>
          <pre class="code">
{
    "status": true,
    "data": {
        "jobIds": [
            "1612514721477"
        ]
    },
    "message": null,
    "code": null,
    "exceptionMessage": null
}</pre>

            <p style="color: #e96900">
                注：实际打印结果以打印任务结果查询接口为准
            </p>

            <h2 id="title4-11">小票模板打印-间隙纸 适用机型:KM118DW,KM118MW,KMSX320,KME20W</h2>
            <p>url:/api/cloud/print/tsplTemplateWrite</p>
          <p>公共参数:</p>
          <table>
            <tr>
              <th>参数名</th>
              <th>含义</th>
              <th>类型</th>
              <th>必传</th>
              <th>示例</th>
            </tr>
            <tr>
              <td>appId</td>
              <td>应用ID</td>
              <td>String</td>
              <td>是</td>
              <td>"1100"</td>
            </tr>
            <tr>
              <td>timestamp</td>
              <td>当前时间</td>
              <td>String</td>
              <td>是</td>
              <td>"2020-08-14 15:29:29"</td>
            </tr>
            <tr>
              <td>sign</td>
              <td>签名值</td>
              <td>String</td>
              <td>是</td>
              <td>"ff9d58e9abcf50dcc423fad782636c21"</td>
            </tr>
          </table>
          <p>私有参数：</p>
            <table>
                <tr>
                    <th>参数名</th>
                    <th>含义</th>
                    <th>类型</th>
                    <th>必传</th>
                    <th>示例与说明</th>
                </tr>
                <tr>
                    <td>sn</td>
                    <td>序列号</td>
                    <td>String</td>
                    <td>是</td>
                    <td>"km789838899"</td>
                </tr>
                <tr>
                    <td>templateId</td>
                    <td>模板id</td>
                    <td>Number</td>
                    <td>是</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>renderData</td>
                    <td>模板动态渲染数据</td>
                    <td>String</td>
                    <td>是</td>
                    <td>
                        "{ \"bindTable1\":[ { \"bindkey1\":\"value1\" } ], \"bindTable2\":[ { \"bindkey1\":\"value1\",
                        \"bindkey2\":\"value2\" } ] }"
                    </td>
                </tr>
            </table>
          <p style="color: #e96900">说明：参数renderData为JSONObject字符串，该JSONObject里的key对应的value值是JSONArray数据结构,具体参考见示例;示例中的bindTable和bindKey对应了字段管理里的表名和字段名;如果有图片传图片的base64数据,图片数据在50KB以内最佳</p>
            <p>入参完整示例：</p>
            <pre class="code">
{
   "sn":"KM118933",
   "templateId": 11,
   "renderData":"{
        \"bindTable1\":[
            {
                \"bindkey1\":\"value1\"
            }
        ],
        \"bindTable2\":[
            {
                \"bindkey1\":\"value1\",
                \"bindkey2\":\"value2\"
            }
        ]
    }",
   "appId":"1100",
    "timestamp":"2020-08-24 11:11:59",
    "sign":"testsignvalue"
}</pre
            >
          <p>响应参数：</p>
          <table>
            <tr>
              <th>参数名</th>
              <th>含义</th>
              <th>类型</th>
              <th>示例与说明</th>
            </tr>
            <tr>
              <td>status</td>
              <td>响应结果</td>
              <td>Boolean</td>
              <td>true为成功，false为失败</td>
            </tr>
            <tr>
              <td>data</td>
              <td>响应数据</td>
              <td>Object</td>
              <td>具体接口的返回数据</td>
            </tr>
            <tr>
              <td>-jobIds</td>
              <td></td>
              <td>Array</td>
              <td>打印任务id</td>
            </tr>
            <tr>
              <td>message</td>
              <td>响应说明</td>
              <td>String</td>
              <td>status为false时，返回信息</td>
            </tr>
            <tr>
              <td>code</td>
              <td>code码</td>
              <td>Number</td>
              <td>详情见最下方code码说明</td>
            </tr>
          </table>
          <p>出参完整示例：</p>
          <pre class="code">
{
    "status": true,
    "data": {
        "jobIds": [
            "1612514721477"
        ]
    },
    "message": null,
    "code": null,
    "exceptionMessage": null
}</pre>

            <p style="color: #e96900">
                注：实际打印结果以打印任务结果查询接口为准
            </p>

            <h2 id="title4-13">
                小票模板打印-连续纸 适用机型:KM118DW,KM118MW,KMUP380C,KME20W,KMDP158,KMDP358,KMDP458</h2>
            <p>url:/api/cloud/print/escTemplatePrint</p>
          <p>公共参数:</p>
          <table>
            <tr>
              <th>参数名</th>
              <th>含义</th>
              <th>类型</th>
              <th>必传</th>
              <th>示例</th>
            </tr>
            <tr>
              <td>appId</td>
              <td>应用ID</td>
              <td>String</td>
              <td>是</td>
              <td>"1100"</td>
            </tr>
            <tr>
              <td>timestamp</td>
              <td>当前时间</td>
              <td>String</td>
              <td>是</td>
              <td>"2020-08-14 15:29:29"</td>
            </tr>
            <tr>
              <td>sign</td>
              <td>签名值</td>
              <td>String</td>
              <td>是</td>
              <td>"ff9d58e9abcf50dcc423fad782636c21"</td>
            </tr>
          </table>
          <p>私有参数：</p>
            <table>
                <tr>
                    <th>参数名</th>
                    <th>含义</th>
                    <th>类型</th>
                    <th>必传</th>
                    <th>示例与说明</th>
                </tr>
                <tr>
                    <td>sn</td>
                    <td>序列号</td>
                    <td>String</td>
                    <td>是</td>
                    <td>"km789838899"</td>
                </tr>
                <tr>
                    <td>templateId</td>
                    <td>模板id</td>
                    <td>Number</td>
                    <td>是</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>renderData</td>
                    <td>模板动态渲染数据</td>
                    <td>String</td>
                    <td>是</td>
                    <td>
                        "{ \"bindTable1\":[ { \"bindkey1\":\"value1\" } ], \"bindTable2\":[ { \"bindkey1\":\"value1\",
                        \"bindkey2\":\"value2\" } ] }"
                    </td>
                </tr>
                <tr>
                    <td>volume</td>
                    <td>音量大小（仅支持有语音的机器）</td>
                    <td>number</td>
                    <td>否</td>
                    <td>80</td>
                </tr>
                <tr>
                    <td>volumeIndex</td>
                    <td>音量索引（仅支持有语音的机器）</td>
                    <td>number</td>
                    <td>否</td>
                    <td>2051（<a href="https://kuaimai-public.oss-cn-shanghai.aliyuncs.com/public/索引语言.xlsx">索引文件下载</a>）</td>
                </tr>
<!--                <tr>-->
<!--                    <td>volumeContent</td>-->
<!--                    <td>音量内容</td>-->
<!--                    <td>String</td>-->
<!--                    <td>否</td>-->
<!--                    <td>"test"</td>-->
<!--                </tr>-->
                <tr>
                    <td>cut</td>
                    <td>是否需要自动切刀（仅支持有切刀机器）</td>
                    <td>Boolean</td>
                    <td>否</td>
                    <td>传值为true，代表需要自动切刀</td>
                </tr>
                <tr>
                    <td>endFeed</td>
                    <td>末尾走行</td>
                    <td>number</td>
                    <td>否</td>
                    <td>默认是9</td>
                </tr>
            </table>
            <p style="color: #e96900">说明：参数renderData为JSONObject字符串，该JSONObject里的key对应的value值是JSONArray数据结构,具体参考见示例;示例中的bindTable和bindKey对应了字段管理里的表名和字段名;如果有图片传图片的base64数据,图片数据在50KB以内最佳</p>
            <p>入参完整示例：</p>
            <pre class="code">
{
    "sn":"KM110h45932",
    "templateId": 11,
    "renderData":"{
        \"bindTable1\":[
            {
                \"bindkey1\":\"value1\"
            }
        ],
        \"bindTable2\":[
            {
                \"bindkey1\":\"value1\",
                \"bindkey2\":\"value2\"
            }
        ]
    }",
    "appId":"100202",
    "timestamp":"2020-08-24 11:11:59",
    "sign":"testsignvalue"
}</pre
            >
          <p>响应参数</p>
          <table>
            <tr>
              <th>参数名</th>
              <th>含义</th>
              <th>类型</th>
              <th>示例</th>
            </tr>
            <tr>
              <td>status</td>
              <td>响应结果</td>
              <td>boolean</td>
              <td>true为成功，false为失败</td>
            </tr>
            <tr>
              <td>data</td>
              <td>响应数据</td>
              <td>object</td>
              <td>接口的返回数据</td>
            </tr>
            <tr>
              <td>message</td>
              <td>响应说明</td>
              <td>String</td>
              <td>status为false时，返回信息</td>
            </tr>
            <tr>
              <td>code</td>
              <td>code码</td>
              <td>Number</td>
              <td>详情见下方code码说明</td>
            </tr>
          </table>

          <h2 id="title4-10">自定义xml打印-间隙纸 适用机型:KM118DW,KM118MW,KMSX320,KME20W</h2>
          <p>url:/api/cloud/print/tsplXmlWrite</p>
          <p>公共参数:</p>
          <table>
            <tr>
              <th>参数名</th>
              <th>含义</th>
              <th>类型</th>
              <th>必传</th>
              <th>示例</th>
            </tr>
            <tr>
              <td>appId</td>
              <td>应用ID</td>
              <td>String</td>
              <td>是</td>
              <td>"1100"</td>
            </tr>
            <tr>
              <td>timestamp</td>
              <td>当前时间</td>
              <td>String</td>
              <td>是</td>
              <td>"2020-08-14 15:29:29"</td>
            </tr>
            <tr>
              <td>sign</td>
              <td>签名值</td>
              <td>String</td>
              <td>是</td>
              <td>"ff9d58e9abcf50dcc423fad782636c21"</td>
            </tr>
          </table>
          <p>私有参数：</p>
          <table>
            <tr>
              <th>参数名</th>
              <th>含义</th>
              <th>类型</th>
              <th>必传</th>
              <th>示例与说明</th>
            </tr>
            <tr>
              <td>sn</td>
              <td>序列号</td>
              <td>String</td>
              <td>是</td>
              <td>"km789838899"</td>
            </tr>
            <tr>
              <td>xmlStr</td>
              <td>打印xml字符串</td>
              <td>String</td>
              <td>是</td>
              <td>xml字符串，具体参考指令模版说明</td>
            </tr>
            <tr>
              <td>image</td>
              <td>打印内容是否包含图片</td>
              <td>Boolean</td>
              <td>否</td>
              <td>false</td>
            </tr>
            <tr>
              <td>printTimes</td>
              <td>打印份数</td>
              <td>Number</td>
              <td>否</td>
              <td>1</td>
            </tr>
          </table>
          <p>入参完整示例：</p>
          <pre class="code">
{
    "sn":"KM110h45932",
    "xmlStr":"&lt;page>&lt;render>&lt;t>hello,word&lt;/t>&lt;/render>&lt;/page>",
    "appId":"100202",
    "timestamp":"2020-08-24 11:11:59",
    "sign":"testsignvalue"
}</pre
          >
          <p>响应参数:</p>
          <table>
            <tr>
              <th>参数名</th>
              <th>含义</th>
              <th>类型</th>
              <th>示例</th>
            </tr>
            <tr>
              <td>status</td>
              <td>响应结果</td>
              <td>boolean</td>
              <td>true为成功，false为失败</td>
            </tr>
            <tr>
              <td>data</td>
              <td>响应数据</td>
              <td>object</td>
              <td>具体接口的返回数据</td>
            </tr>
            <tr>
              <td>message</td>
              <td>响应说明</td>
              <td>String</td>
              <td>status为false时，返回信息</td>
            </tr>
            <tr>
              <td>code</td>
              <td>code码</td>
              <td>Number</td>
              <td>详情见最下方code码说明</td>
            </tr>
          </table>

          <p style="color: #e96900">
            注：实际打印结果以打印任务结果查询接口为准
          </p>

          <p>xmlStr参数指令模版说明：</p>
          <p>1.指令整体模板</p>
          <pre class="code">
&lt;page>
    &lt;render>
        &lt;t>hello&lt;/t>
        &lt;bc x='50'
            y='50'
            >209490224913&lt;/bc>
        &lt;qrc x='100' y='100'>529348520501&lt;/qrc>
  &lt;/render>
&lt;/page></pre
          >
          <p>2.各指令标签详情</p>
          <p>页元素-page</p>
          <pre class="code">
&lt;page>&lt;/page>
描述：定义打印纸张</pre
          >
          <p>根元素-render</p>
          <pre class="code">
&lt;render width='75' height='100'>&lt;/render>
描述：定义渲染区域

width:
  渲染宽度，默认是75（单位毫米）
height:
  渲染高度，默认是100（单位毫米）,最大支持220mm</pre
          >
          <p>打印元素-t</p>
          <pre class="code">
&lt;t x='1' y='1' font='1' rotate='0' xMultiple=‘1’ yMultiple='1' bold='1' waterMark='0' bgbX='1' bgbY='1' bgbWidth='50' bgbHeight='50'>hello&lt;/t>
描述：定义打印文本

x:
 起始x坐标，默认是1（单位像素，8像素为1毫米）
y:
 起始y坐标，默认是1（单位像素，8像素为1毫米）
font:
 字体名称；默认是TSS24.BF2

TSS16.BF2: TSS 16*16点阵
TSS24.BF2: TSS 24*24点阵


rotate:
 旋转角度，取值：0,90,180,270; 默认是0
xMultiple:
 宽的倍数, 默认是1
yMultiple:
 高的倍数，默认是1
bold:
 是否加粗,0:不加粗；1:加粗,默认是0
waterMark:
 水印浓度,取值0～100,0表示取消水印,默认是0
bgbX,bgbY,bgbWidth,bgbHeight:设置文字背景为黑色,分别为起始位置x,y和宽度,高度;默认是无</pre
          >
          <p>打印元素-bc</p>
          <pre class="code">
&lt;bc x='1' y='1' codeType='128' height='100' style='1' rotate='0' narrow='2' wide='2'>209490224913&lt;/bc>
描述：定义打印条形码

x:
 起始x坐标，默认是1
y:
 起始y坐标，默认是1
codeType:
 条形码样式，默认是128
128: Code 128, switching code subset automatically.

height:
  高度，单位dot,默认100
style:
  内容样式,默认是2
0:不可见；1:可见巨左；2:可见居中；3:可见居右
rotate:
 旋转角度，取值：0,90,180,270; 默认是0
narrow:
 窄条宽度，单位dot，默认2
wide:
 宽条宽度，单位dot，默认2</pre
          >
          <p>打印元素-qrc</p>
          <pre class="code">
&lt;qrc x='1' y='1' eccLevel='L' cellWidth='7' mode='M' rotate='0' model='M1' mask='S3'>529348520501&lt;/qrc>
描述：定义打印二维码

x:
 起始x坐标，默认是1
y:
 起始y坐标，默认是1
cellWidth:
 高度宽度,默认是7
rotate:
 旋转角度,默认是0
eccLevel:
 辨识等级,默认是L
mode:
 0:Auto, 1:manual,默认是0
model:
 1-default M1 ,2-M,默认是1
mask:
 S[0-8],默认是S3,二维码的种类，控制二维码的样式</pre
          >
          <p>打印元素-img</p>
          <pre class="code">
&lt;img x='1' y='1' >base64&lt;/img>
 图片传图片的base64数据,图片数据在50KB以内最佳
x:
 坐标x
y:
 坐标y</pre
          >
          <p>打印元素-box</p>
          <pre class="code">
&lt;box x='1' y='1' xEnd='100' yEnd='50' thickness='1' radius='0' />
描述：定义线框

x:
 起始x坐标,默认是1
y:
 起始y坐标,默认是1
xEnd:
 x结束点,默认是100
yEnd:
 y结束点,默认是100
thickness:
 线条宽度,默认是1
radius:
 弧度半径,默认是0</pre
          >
          <p>打印元素-circle</p>
          <pre class="code">
&lt;circle x='1' y='1' diameter='100' thickness='1'/>
描述：定义圆

x:
 起始x坐标,默认是1
y:
 起始y坐标,默认是1
thickness:
 线条宽度,默认是1
diameter:
 直径,默认是100</pre
          >
          <p>打印元素-ellipse</p>
          <pre class="code">
&lt;ellipse x='1' y='1' width='100' height='50' thickness='1'/>
描述：定义圆

x:
 起始x坐标,默认是1
y:
 起始y坐标,默认是1
width:
 宽度,默认是100
height:
 高度,默认是50
thickness:
 线条宽度,默认是1</pre
          >

          <p>打印元素-bar</p>
          <pre class="code">
&lt;bar x='1' y='1' width='100' height='4'/>
描述：定义线条

x:
 起始x坐标,默认是1
y:
 起始y坐标,默认是1
width:
 线条宽度,默认是10
height:
 线条高度,默认是10
</pre>

          <h2 id="title4-8">自定义xml打印-连续纸 适用机型:KMDP158,KMDP358,KMDP458,KM118DW,KM118MW,KME20W,KMUP380C</h2>
          <p>url:/api/cloud/print/escXmlWrite</p>
          <p>公共参数:</p>
          <table>
            <tr>
              <th>参数名</th>
              <th>含义</th>
              <th>类型</th>
              <th>必传</th>
              <th>示例</th>
            </tr>
            <tr>
              <td>appId</td>
              <td>应用ID</td>
              <td>String</td>
              <td>是</td>
              <td>"1100"</td>
            </tr>
            <tr>
              <td>timestamp</td>
              <td>当前时间</td>
              <td>String</td>
              <td>是</td>
              <td>"2020-08-14 15:29:29"</td>
            </tr>
            <tr>
              <td>sign</td>
              <td>签名值</td>
              <td>String</td>
              <td>是</td>
              <td>"ff9d58e9abcf50dcc423fad782636c21"</td>
            </tr>
          </table>
          <p>私有参数：</p>
          <table>
            <tr>
              <th>参数名</th>
              <th>含义</th>
              <th>类型</th>
              <th>必传</th>
              <th>示例与说明</th>
            </tr>
            <tr>
              <td>sn</td>
              <td>序列号</td>
              <td>String</td>
              <td>是</td>
              <td>"km789838899"</td>
            </tr>
            <tr>
              <td>instructions</td>
              <td>打印指令内容</td>
              <td>String</td>
              <td>是</td>
              <td>xml字符串，具体参考指令模版说明</td>
            </tr>
            <tr>
              <td>volume</td>
              <td>音量大小（仅支持有语音的机器）</td>
              <td>number</td>
              <td>否</td>
              <td>80</td>
            </tr>
            <tr>
              <td>volumeIndex</td>
              <td>音量索引（仅支持有语音的机器）</td>
              <td>number</td>
              <td>否</td>
              <td>2051(<a href="https://kuaimai-public.oss-cn-shanghai.aliyuncs.com/public/索引语言.xlsx">索引文件下载</a>)</td>
            </tr>
            <!--                <tr>-->
            <!--                    <td>volumeContent</td>-->
            <!--                    <td>音量内容</td>-->
            <!--                    <td>String</td>-->
            <!--                    <td>否</td>-->
            <!--                    <td>"test"</td>-->
            <!--                </tr>-->
            <tr>
              <td>cut</td>
              <td>是否需要自动切刀（仅支持有切刀机器）</td>
              <td>Number</td>
              <td>否</td>
              <td>传值为1，代表需要自动切刀</td>
            </tr>
          </table>
          <p>入参完整示例：</p>
          <pre class="code">
{
    "sn":"KM110h45932",
    "instructions":"&lt;page>&lt;render>&lt;t size='10'>hello,word&lt;/t>&lt;/render>&lt;/page>",
    "appId":"100202",
    "timestamp":"2020-08-24 11:11:59",
    "sign":"testsignvalue"
}</pre
          >
          <p>响应参数：</p>
          <table>
            <tr>
              <th>参数名</th>
              <th>含义</th>
              <th>类型</th>
              <th>示例</th>
            </tr>
            <tr>
              <td>status</td>
              <td>响应结果</td>
              <td>boolean</td>
              <td>true为成功，false为失败</td>
            </tr>
            <tr>
              <td>data</td>
              <td>响应数据</td>
              <td>object</td>
              <td>具体接口的返回数据</td>
            </tr>
            <tr>
              <td>message</td>
              <td>响应说明</td>
              <td>String</td>
              <td>status为false时，返回信息</td>
            </tr>
            <tr>
              <td>code</td>
              <td>code码</td>
              <td>Number</td>
              <td>详情见最下方code码说明</td>
            </tr>
          </table>
          <p>xmlStr参数指令模版说明：</p>
          <p>1.指令整体模板</p>
          <pre class="code">
&lt;page>
    &lt;render>
        &lt;t size='10'>hello&lt;/t>
        &lt;bc model='65'
            height='80'
            showT='down'
            wide='8'>209490224913&lt;/bc>
        &lt;qrc size='5'>529348520501&lt;/qrc>
    &lt;/render>
&lt;/page></pre
          >
          <p>2.各指令标签详情</p>
          <p>页元素-page</p>
          <pre class="code">
&lt;page>&lt;/page>
描述：定义打印纸张</pre
          >
          <p>根元素-render</p>
          <pre class="code">
&lt;render>&lt;/render>
描述：定义渲染区域</pre
          >
          <p>打印元素-t</p>
          <pre class="code">
&lt;t size='10' align='center' feed='1' x='1'>hello&lt;/t>
描述：定义打印文本

size：
 字体大小，取值：
 00-默认
 10-2倍宽
 20-3倍宽
 30-4倍宽
 40-5倍宽
 50-6倍宽
 60-7倍宽
 70-8倍宽
 01-2倍高
 02-3倍高
 03-4倍高
 04-5倍高
 05-6倍高
 06-7倍高
 07-8倍高
 11-2倍宽和2倍高...

align:
 对齐方式，取值：center-居中，left-左对齐，right-右对齐,默认居中
feed:
 回车换行步数，默认是1
当feed为0的时候不换行，可以通过x设置下一个标签的起始位置,x的单位为dot;</pre
          >
          <p>打印元素-bc</p>
          <pre class="code">
&lt;bc model='73' height='80' showT='down' wide='8' font='0' align='left' feed='1'>209490224913&lt;/bc>
描述：定义打印条形码

model：条形码类型，取值0～6，65～73，默认73
0:UPC-A  1:UPC-E   2:JAN13  3:JAN8  4:CODE39  5:ITF  6:CODABAR  65:UPC-A 66:UPC-E  67:JAN13  68:JAN8  69:CODE39  70:ITF  71:CODABAR  72:CODE93  73:CODE128
height：条形码的高度，单位点(dot) 取值：1～255
showT：文字显示，取值down、up、false，非必填属性，默认down
wide：条形码宽条的宽度，单位点(dot)取值：2～6
font: 字体,0-标准,1-压缩
align:
 对齐方式，取值：center-居中，left-左对齐，right-右对齐,默认左对齐
feed:
 回车换行步数，默认是1</pre
          >
          <p>打印元素-qrc</p>
          <pre class="code">
&lt;qrc size='5' align='left' feed='1'>529348520501&lt;/qrc>
描述：定义打印二维码

size：二维码的大小序号，取值0~15，默认3
align:
 对齐方式，取值：center-居中，left-左对齐，right-右对齐,默认左对齐
feed:
 回车换行步数，默认是1</pre
          >
          <p>打印元素-img</p>
          <pre class="code">
&lt;img size='0' align='left' feed='1'>base64&lt;/img>
描述：定义打印图片,图片传图片的base64数据,图片大小在50KB以内最佳

size：图片的大小，0-正常大小，1-图片宽度扩大，2-图片高度扩大，3-图片宽度和高度同时扩大，默认是0
align:
 对齐方式，取值：center-居中，left-左对齐，right-右对齐,默认左对齐
feed:
 回车换行步数，默认是1</pre
          >

            <h2 id="title4-2">TSPL/ESC指令打印 适用机型:KM118DW,KM118MW,KMSX320,KME20W</h2>
            <p>url:/api/cloud/print/deviceWrite</p>
          <p>公共参数:</p>
          <table>
            <tr>
              <th>参数名</th>
              <th>含义</th>
              <th>类型</th>
              <th>必传</th>
              <th>示例</th>
            </tr>
            <tr>
              <td>appId</td>
              <td>应用ID</td>
              <td>String</td>
              <td>是</td>
              <td>"1100"</td>
            </tr>
            <tr>
              <td>timestamp</td>
              <td>当前时间</td>
              <td>String</td>
              <td>是</td>
              <td>"2020-08-14 15:29:29"</td>
            </tr>
            <tr>
              <td>sign</td>
              <td>签名值</td>
              <td>String</td>
              <td>是</td>
              <td>"ff9d58e9abcf50dcc423fad782636c21"</td>
            </tr>
          </table>
          <p>私有参数：</p>
            <table>
                <tr>
                    <th>参数名</th>
                    <th>含义</th>
                    <th>类型</th>
                    <th>必传</th>
                    <th>示例与说明</th>
                </tr>
                <tr>
                    <td>sn</td>
                    <td>序列号</td>
                    <td>String</td>
                    <td>是</td>
                    <td>"km789838899"</td>
                </tr>
                <tr>
                    <td>instructs</td>
                    <td>打印指令</td>
                    <td>String</td>
                    <td>是</td>
                    <td>“**”</td>
                </tr>

            </table>
            <p>入参完整示例：</p>
            <pre class="code">
{
    "sn":"1609142313064",
    "instructs":"CLS\r\nSIZE 75mm,100mm\r\nTEXT 50,50,\"0\",0,1,1,\"Hellow World!\"\r\nPRINT 1\r\n",
    "appId":"100202",
    "timestamp":"2020-08-24 11:11:59",
    "sign":"testsignvalue"
}</pre
            >
            <p>响应参数：</p>
            <table>
                <tr>
                    <th>参数名</th>
                    <th>含义</th>
                    <th>类型</th>
                    <th>示例与说明</th>
                </tr>
                <tr>
                    <td>status</td>
                    <td>响应结果</td>
                    <td>Boolean</td>
                    <td>true为成功，false为失败</td>
                </tr>
                <tr>
                    <td>data</td>
                    <td>响应数据</td>
                    <td>Object</td>
                    <td>具体接口的返回数据</td>
                </tr>
                <tr>
                    <td>-jobIds</td>
                    <td></td>
                    <td>Array</td>
                    <td>打印任务id</td>
                </tr>
                <tr>
                    <td>message</td>
                    <td>响应说明</td>
                    <td>String</td>
                    <td>status为false时，返回信息</td>
                </tr>
            </table>
            <p>出参完整示例：</p>
            <pre class="code">
{
    "status": true,
    "data": {
        "jobIds": [
            "1612514721477"
        ]
    },
    "message": null,
    "code": null,
    "exceptionMessage": null
}</pre
            >

            <p style="color: #e96900">
                注：实际打印结果以打印任务结果查询接口为准
            </p>





            <h2 id="title4-4">ESC指令打印 适用机型:KMDP158,KMDP358,KMDP458,KMUP380C</h2>
            <p>url:/api/cloud/print/escWrite</p>
          <p>公共参数:</p>
          <table>
            <tr>
              <th>参数名</th>
              <th>含义</th>
              <th>类型</th>
              <th>必传</th>
              <th>示例</th>
            </tr>
            <tr>
              <td>appId</td>
              <td>应用ID</td>
              <td>String</td>
              <td>是</td>
              <td>"1100"</td>
            </tr>
            <tr>
              <td>timestamp</td>
              <td>当前时间</td>
              <td>String</td>
              <td>是</td>
              <td>"2020-08-14 15:29:29"</td>
            </tr>
            <tr>
              <td>sign</td>
              <td>签名值</td>
              <td>String</td>
              <td>是</td>
              <td>"ff9d58e9abcf50dcc423fad782636c21"</td>
            </tr>
          </table>
          <p>私有参数：</p>
            <table>
                <tr>
                    <th>参数名</th>
                    <th>含义</th>
                    <th>类型</th>
                    <th>必传</th>
                    <th>示例与说明</th>
                </tr>
                <tr>
                    <td>sn</td>
                    <td>序列号</td>
                    <td>String</td>
                    <td>是</td>
                    <td>"km789838899"</td>
                </tr>
                <tr>
                    <td>instructionsList</td>
                    <td>打印指令内容</td>
                    <td>String</td>
                    <td>是</td>
                    <td>base64编码之后的esc指令</td>
                </tr>
                <tr>
                    <td>copies</td>
                    <td>复制打印次数（最大支持30次）</td>
                    <td>number</td>
                    <td>否</td>
                    <td>1</td>
                </tr>
            </table>
            <p>入参完整示例：</p>
            <pre class="code">
{
    "sn":"KM110h45932",
    "instructionsList":"H3MCAAAAAAD/AAAdIQAbYQHJzLzSwaqjqLK5tPKjqQ0KHSERG2EBz7rB+tC3t++6o8/K1uDB+s+6KM3ytO",
    "appId":"100202",
    "timestamp":"2020-08-24 11:11:59",
    "sign":"testsignvalue"
}</pre
            >
            <p>响应参数：</p>
          <table>
            <tr>
              <th>参数名</th>
              <th>含义</th>
              <th>类型</th>
              <th>示例</th>
            </tr>
            <tr>
              <td>status</td>
              <td>响应结果</td>
              <td>boolean</td>
              <td>true为成功，false为失败</td>
            </tr>
            <tr>
              <td>data</td>
              <td>响应数据</td>
              <td>object</td>
              <td>具体接口的返回数据</td>
            </tr>
            <tr>
              <td>message</td>
              <td>响应说明</td>
              <td>String</td>
              <td>status为false时，返回信息</td>
            </tr>
            <tr>
              <td>code</td>
              <td>code码</td>
              <td>Number</td>
              <td>详情见最下方code码说明</td>
            </tr>
          </table>



            <!-- <h2 id="title4-5">小票机logo上传</h2>
            <p>url:/api/cloud/print/escLogoWrite</p>
            <p>请求参数：</p>
            <table>
                <tr>
                    <th>参数名</th>
                    <th>含义</th>
                    <th>类型</th>
                    <th>必传</th>
                    <th>示例与说明</th>
                </tr>
                <tr>
                    <td>sn</td>
                    <td>序列号</td>
                    <td>String</td>
                    <td>是</td>
                    <td>"km789838899"</td>
                </tr>
                <tr>
                    <td>instructionsList</td>
                    <td>打印指令内容</td>
                    <td>String</td>
                    <td>是</td>
                    <td></td>
                </tr>
            </table>
            <p>入参完整示例：</p>
            <pre class="code">
{
    "sn":"KM110h45932",
    "instructionsList":"H3MCAAAAAAD/AAAdIQAbYQHJzLzSwaqjq",
    "appId":"100202",
    "timestamp":"2020-08-24 11:11:59",
    "sign":"testsignvalue"
}</pre
            >
            <p>响应参数：见公共响应参数</p> -->

<!--            <h2 id="title4-6">打印机状态查询</h2>-->
<!--            <p>url:/api/cloud/device/status</p>-->
<!--          <p>公共参数:</p>-->
<!--          <table>-->
<!--            <tr>-->
<!--              <th>参数名</th>-->
<!--              <th>含义</th>-->
<!--              <th>类型</th>-->
<!--              <th>必传</th>-->
<!--              <th>示例</th>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td>appId</td>-->
<!--              <td>应用ID</td>-->
<!--              <td>String</td>-->
<!--              <td>是</td>-->
<!--              <td>"1100"</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td>timestamp</td>-->
<!--              <td>当前时间</td>-->
<!--              <td>String</td>-->
<!--              <td>是</td>-->
<!--              <td>"2020-08-14 15:29:29"</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td>sign</td>-->
<!--              <td>签名值</td>-->
<!--              <td>String</td>-->
<!--              <td>是</td>-->
<!--              <td>"ff9d58e9abcf50dcc423fad782636c21"</td>-->
<!--            </tr>-->
<!--          </table>-->
<!--          <p>私有参数：</p>-->
<!--            <table>-->
<!--                <tr>-->
<!--                    <th>参数名</th>-->
<!--                    <th>含义</th>-->
<!--                    <th>类型</th>-->
<!--                    <th>必传</th>-->
<!--                    <th>示例与说明</th>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <td>sn</td>-->
<!--                    <td>序列号</td>-->
<!--                    <td>String</td>-->
<!--                    <td>是</td>-->
<!--                    <td>"km789838899"</td>-->
<!--                </tr>-->
<!--            </table>-->
<!--            <p>入参完整示例：</p>-->
<!--            <pre class="code">-->
<!--{-->
<!--    "sn":"KM110h45932",-->
<!--    "appId":"100202",-->
<!--    "timestamp":"2020-08-24 11:11:59",-->
<!--    "sign":"testsignvalue"-->
<!--}</pre-->
<!--            >-->
<!--            <p>响应参数：</p>-->
<!--            <table>-->
<!--                <tr>-->
<!--                    <th>参数名</th>-->
<!--                    <th>含义</th>-->
<!--                    <th>类型</th>-->
<!--                    <th>必传</th>-->
<!--                    <th>说明</th>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <td>status</td>-->
<!--                    <td>响应结果</td>-->
<!--                    <td>boolean</td>-->
<!--                    <td>是</td>-->
<!--                    <td>true为成功，false为失败</td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <td>data</td>-->
<!--                    <td>响应结果</td>-->
<!--                    <td>object</td>-->
<!--                    <td>否</td>-->
<!--                    <td>ONLINE:在线; OFFLINE:离线;UNACTIVE:未激活; DISABLE:已禁用</td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <td>message</td>-->
<!--                    <td>响应结果</td>-->
<!--                    <td>String</td>-->
<!--                    <td>否</td>-->
<!--                    <td>status为false时，返回信息</td>-->
<!--                </tr>-->
<!--            </table>-->

          <h2 id="title4-3">打印任务结果查询</h2>
          <p>url:/api/cloud/print/result</p>
          <p>公共参数:</p>
          <table>
            <tr>
              <th>参数名</th>
              <th>含义</th>
              <th>类型</th>
              <th>必传</th>
              <th>示例</th>
            </tr>
            <tr>
              <td>appId</td>
              <td>应用ID</td>
              <td>String</td>
              <td>是</td>
              <td>"1100"</td>
            </tr>
            <tr>
              <td>timestamp</td>
              <td>当前时间</td>
              <td>String</td>
              <td>是</td>
              <td>"2020-08-14 15:29:29"</td>
            </tr>
            <tr>
              <td>sign</td>
              <td>签名值</td>
              <td>String</td>
              <td>是</td>
              <td>"ff9d58e9abcf50dcc423fad782636c21"</td>
            </tr>
          </table>
          <p>私有参数：</p>
          <table>
            <tr>
              <th>参数名</th>
              <th>含义</th>
              <th>类型</th>
              <th>必传</th>
              <th>示例与说明</th>
            </tr>
            <tr>
              <td>sn</td>
              <td>序列号</td>
              <td>String</td>
              <td>是</td>
              <td>"km789838899"</td>
            </tr>
            <tr>
              <td>jobIdStr</td>
              <td>打印任务jobId</td>
              <td>String</td>
              <td>是</td>
              <td>"[\"111\",\"222\"]"</td>
            </tr>
          </table>
          <p style="color: #e96900">说明:jobIdStr参数为数组结构字符串</p>
          <p>入参完整示例：</p>
          <pre class="code">
{
    "sn": "KM111112222",
    "jobIdStr": "[\"111\",\"222\"]"
}</pre
          >
          <p>响应参数：</p>
          <table>
            <tr>
              <th>参数名</th>
              <th>含义</th>
              <th>类型</th>
              <th>示例与说明</th>
            </tr>
            <tr>
              <td>status</td>
              <td>响应结果</td>
              <td>Boolean</td>
              <td>true为成功，false为失败</td>
            </tr>
            <tr>
              <td>data</td>
              <td>响应数据</td>
              <td>Array</td>
              <td>具体接口的返回数据</td>
            </tr>
            <tr>
              <td>-jobId</td>
              <td>打印任务id</td>
              <td>String</td>
              <td>打印任务id</td>
            </tr>
            <tr>
              <td>-result</td>
              <td>打印结果</td>
              <td>bolean</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>-desc</td>
              <td>结果描述</td>
              <td>String</td>
              <td>打印结果说明</td>
            </tr>
            <tr>
              <td>-code</td>
              <td>code码</td>
              <td>String</td>
              <td>注：result为true并且code为2000时，表示打印成功； result为false并且code为2007/2004 为打印失败</td>
            </tr>
            <tr>
              <td>message</td>
              <td>响应说明</td>
              <td>String</td>
              <td>status为false时，返回信息</td>
            </tr>
            <tr>
              <td>code</td>
              <td>code码</td>
              <td>Number</td>
              <td>详情见最下方code码说明</td>
            </tr>
          </table>
          <p>出参完整示例：</p>
          <pre class="code">
{
    "status": true,
    "data": [
        {
            "result": true,
            "desc": "打印成功",
            "jobId": "1612514721477"
        }
    ],
    "message": null,
    "code": null,
    "exceptionMessage": null
}</pre
          >

            <h2 id="title4-9">打印机状态查询</h2>
            <p>url:/api/cloud/device/batchStatus</p>
          <p>公共参数:</p>
          <table>
            <tr>
              <th>参数名</th>
              <th>含义</th>
              <th>类型</th>
              <th>必传</th>
              <th>示例</th>
            </tr>
            <tr>
              <td>appId</td>
              <td>应用ID</td>
              <td>String</td>
              <td>是</td>
              <td>"1100"</td>
            </tr>
            <tr>
              <td>timestamp</td>
              <td>当前时间</td>
              <td>String</td>
              <td>是</td>
              <td>"2020-08-14 15:29:29"</td>
            </tr>
            <tr>
              <td>sign</td>
              <td>签名值</td>
              <td>String</td>
              <td>是</td>
              <td>"ff9d58e9abcf50dcc423fad782636c21"</td>
            </tr>
          </table>
          <p>私有参数：</p>
            <table>
                <tr>
                    <th>参数名</th>
                    <th>含义</th>
                    <th>类型</th>
                    <th>必传</th>
                    <th>示例与说明</th>
                </tr>
                <tr>
                    <td>snsStr</td>
                    <td>序列号</td>
                    <td>String</td>
                    <td>是</td>
                    <td>"[\"222\",\"444\"]"</td>
                </tr>
            </table>
          <p style="color: #e96900">说明:snsStr参数为数组结构字符串</p>
            <p>入参完整示例：</p>
            <pre class="code">
{
    "snsStr":"[\"222\",\"444\"]",
    "appId":"100202",
    "timestamp":"2020-08-24 11:11:59",
    "sign":"testsignvalue"
}</pre
            >
            <p>响应参数：</p>
            <table>
                <tr>
                    <th>参数名</th>
                    <th>含义</th>
                    <th>类型</th>
                    <th>说明</th>
                </tr>
                <tr>
                    <td>status</td>
                    <td>响应结果</td>
                    <td>boolean</td>
                    <td>true为成功，false为失败</td>
                </tr>
                <tr>
                    <td>data</td>
                    <td>响应结果</td>
                    <td>object</td>
                    <td></td>
                </tr>
                <tr>
                    <td>-sn</td>
                    <td>序列号</td>
                    <td></td>
                    <td>String</td>
                    <td></td>
                </tr>
                <tr>
                    <td>-status</td>
                    <td>状态值</td>
                    <td>String</td>
                    <td>ONLINE:在线; OFFLINE:离线;UNACTIVE:未激活; DISABLE:已禁用</td>
                </tr>
                <tr>
                    <td>message</td>
                    <td>响应结果</td>
                    <td>String</td>
                    <td>status为false时，返回信息</td>
                </tr>
              <tr>
                <td>code</td>
                <td>code码</td>
                <td>Number</td>
                <td>详情见最下方code码说明</td>
              </tr>
            </table>
            <p>出参完整示例：</p>
            <pre class="code">
{
    "status":true,
    "data":[
        {
            "sn":"KM1111",
            "status":"ONLINE"
        },
        {
            "sn":"KM2222",
            "status":"ONLINE"
        }
    ],
    "message":null,
    "code":null,
    "exceptionMessage":null
}</pre
            >

<!--            <h2 id="title4-7">查询打印机是否存在</h2>-->
<!--            <p>url:/api/cloud/device/exist</p>-->
<!--          <p>公共参数:</p>-->
<!--          <table>-->
<!--            <tr>-->
<!--              <th>参数名</th>-->
<!--              <th>含义</th>-->
<!--              <th>类型</th>-->
<!--              <th>必传</th>-->
<!--              <th>示例</th>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td>appId</td>-->
<!--              <td>应用ID</td>-->
<!--              <td>String</td>-->
<!--              <td>是</td>-->
<!--              <td>"1100"</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td>timestamp</td>-->
<!--              <td>当前时间</td>-->
<!--              <td>String</td>-->
<!--              <td>是</td>-->
<!--              <td>"2020-08-14 15:29:29"</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td>sign</td>-->
<!--              <td>签名值</td>-->
<!--              <td>String</td>-->
<!--              <td>是</td>-->
<!--              <td>"ff9d58e9abcf50dcc423fad782636c21"</td>-->
<!--            </tr>-->
<!--          </table>-->
<!--          <p>私有参数：</p>-->
<!--            <table>-->
<!--                <tr>-->
<!--                    <th>参数名</th>-->
<!--                    <th>含义</th>-->
<!--                    <th>类型</th>-->
<!--                    <th>必传</th>-->
<!--                    <th>示例与说明</th>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <td>sn</td>-->
<!--                    <td>序列号</td>-->
<!--                    <td>String</td>-->
<!--                    <td>是</td>-->
<!--                    <td>"km789838899"</td>-->
<!--                </tr>-->
<!--            </table>-->
<!--            <p>入参完整示例：</p>-->
<!--            <pre class="code">-->
<!--{-->
<!--    "sn":"KM110h45932",-->
<!--    "appId":"100202",-->
<!--    "timestamp":"2020-08-24 11:11:59",-->
<!--    "sign":"testsignvalue"-->
<!--}</pre-->
<!--            >-->
<!--            <p>响应参数：</p>-->
<!--            <table>-->
<!--                <tr>-->
<!--                    <th>参数名</th>-->
<!--                    <th>含义</th>-->
<!--                    <th>类型</th>-->
<!--                    <th>必传</th>-->
<!--                    <th>示例与说明</th>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <td>status</td>-->
<!--                    <td>响应结果</td>-->
<!--                    <td>boolean</td>-->
<!--                    <td>是</td>-->
<!--                    <td>true为成功，false为失败</td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <td>data</td>-->
<!--                    <td>响应数据</td>-->
<!--                    <td>object</td>-->
<!--                    <td>否</td>-->
<!--                    <td>false：未上云；true: 已上云</td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                    <td>message</td>-->
<!--                    <td>响应说明</td>-->
<!--                    <td>String</td>-->
<!--                    <td>否</td>-->
<!--                    <td>status为false时，返回信息</td>-->
<!--                </tr>-->
<!--            </table>-->

            <h2 id="title4-15">错误码</h2>
            <table>
                <tr>
                    <th>code码</th>
                    <th>描述</th>
                </tr>
                <tr>
                    <td>4000</td>
                    <td>序列号为空</td>
                </tr>
                <tr>
                    <td>4001</td>
                    <td>序列号不存在或未激活</td>
                </tr>
                <tr>
                    <td>4002</td>
                    <td>重打次数超过了上限</td>
                </tr>
                <tr>
                    <td>4003</td>
                    <td>设备状态校验失败</td>
                </tr>
                <tr>
                    <td>4005</td>
                    <td>设备非在线状态</td>
                </tr>
                <tr>
                    <td>4006</td>
                    <td>设备状态异常</td>
                </tr>
                <tr>
                    <td>4008</td>
                    <td>设备已经被绑定</td>
                </tr>
                <tr>
                    <td>4009</td>
                    <td>设备密钥错误</td>
                </tr>
                <tr>
                    <td>4010</td>
                    <td>设备任务下发失败</td>
                </tr>
                <tr>
                    <td>6006</td>
                    <td>必传参数有内空</td>
                </tr>
                <tr>
                    <td>6009</td>
                    <td>数据结构错误</td>
                </tr>
                <tr>
                    <td>6010</td>
                    <td>模板类型错误</td>
                </tr>
                <tr>
                    <td>6011</td>
                    <td>模板数据解析错误</td>
                </tr>
                <tr>
                    <td>6012</td>
                    <td>设备已经被绑定</td>
                </tr>
                <tr>
                    <td>6014</td>
                    <td>无效模板</td>
                </tr>
                <tr>
                    <td>6015</td>
                    <td>无权限模板</td>
                </tr>
              <tr>
                <td>100002</td>
                <td>机器缺纸</td>
              </tr>
              <tr>
                <td>100003</td>
                <td>机器盖子未闭合</td>
              </tr>
              <tr>
                <td>100004</td>
                <td>机器内存不足</td>
              </tr>
            </table>

            <!-- <h2 id="title4-8">查询打印机基础信息</h2>
            <p>url:/api/cloud/device/info</p>
            <p>请求参数：</p>
            <table>
                <tr>
                    <th>参数名</th>
                    <th>含义</th>
                    <th>类型</th>
                    <th>必传</th>
                    <th>示例与说明</th>
                </tr>
                <tr>
                    <td>sn</td>
                    <td>序列号</td>
                    <td>String</td>
                    <td>是</td>
                    <td>"km789838899"</td>
                </tr>
            </table>
            <p>入参完整示例：</p>
            <pre class="code">
{
    "sn":"KM110h45932",
    "appId":"100202",
    "timestamp":"2020-08-24 11:11:59",
    "sign":"testsignvalue"
}</pre
            >
            <p>响应参数：</p>
            <table>
                <tr>
                    <th>参数名</th>
                    <th>含义</th>
                    <th>类型</th>
                    <th>必传</th>
                    <th>示例与说明</th>
                </tr>
                <tr>
                    <td>status</td>
                    <td>响应结果</td>
                    <td>boolean</td>
                    <td>是</td>
                    <td>true为成功，false为失败</td>
                </tr>
                <tr>
                    <td>data</td>
                    <td>响应数据</td>
                    <td>object</td>
                    <td>否</td>
                    <td>false：未上云；true: 已上云</td>
                </tr>
                <tr>
                    <td>-productName</td>
                    <td></td>
                    <td>String</td>
                    <td>否</td>
                    <td>设备型号</td>
                </tr>
                <tr>
                    <td>message</td>
                    <td>响应说明</td>
                    <td>String</td>
                    <td>否</td>
                    <td>status为false时，返回信息</td>
                </tr>
            </table> -->

<!--            <h1 id="title5">SDK列表</h1>-->
<!--            <p>-->
<!--                JAVA版：<a href="https://github.com/xuli2016/kuaimai-cloud-demo"-->
<!--            >https://github.com/xuli2016/kuaimai-cloud-demo</a-->
<!--            >-->
<!--            </p>-->
        </div>
    </div>
</template>

<script>
import hljs from 'highlight.js';
// import 'highlight.js/styles/github-dark-dimmed.css';
import 'highlight.js/styles/github.css'

export default {
    name: "Api",
    data() {
        return {
            fixMenu: false,
        };
    },
    mounted() {
        hljs.highlightAll()

        let id = window.location.href.split("?id=")[1] || "";
        if (id) {
            this.scrollId(id);
        }
        window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
        document.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll() {
            //获取滚动时的高度
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            if (scrollTop > 400) {
                //大于400的时候要做的操作
                this.fixMenu = false;
            } else {
                this.fixMenu = false;
            }
        },
        scrollId(id) {
            let id1 = window.location.href.split("?id=")[1] || "";
            if (id !== id1) {
                window.location.href = window.location.href.split("?id=")[0] + "?id=" + id;
            }
            document.getElementById(id).scrollIntoView(true);
            console.log(document.getElementById(id));
        },
    },
};
</script>

<style lang="scss" scoped>
.api {
  //padding: 24rem;
  //display: flex;
  font-size: 16px;
  flex-direction: row;

  .left-menu {
    position: fixed;
    top: 160rem;
    left: -240rem;
    flex: 0 0 auto;
    padding: 12rem 0;
    width: 260rem;
    max-height: 70%;
    overflow-y: auto;
    background: #fafafa;
    transition: left 0.5s;
    border-radius: 4rem;
    box-shadow: 0 0 14rem rgba(255, 105, 50, 0.5);
    border-right: 4rem solid rgba(255, 105, 50, 0.5);
    animation: leftFloat 1s ease-in-out infinite;

    &:hover {
      left: 0rem;
      transition: left 0.5s;
      animation: leftFloat1 0.5s infinite;
    }

    &.fix-menu {
      left: 0rem;
      transition: left 0.5s;
      animation: leftFloat1 0.5s infinite;
    }

    .block-title {
      display: block;
      padding-left: 24rem;
      font-size: 14rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #262626;
      line-height: 42rem;
      cursor: pointer;

      &:hover {
        color: #ff6932;
        background: #f0f0f0;
      }
    }

    .s-block-title {
      display: block;
      padding-left: 40rem;
      font-size: 14rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #555555;
      line-height: 32rem;
      cursor: pointer;

      &:hover {
        color: #ff6932;
        background: #f0f0f0;
      }
    }
  }

  .content {
    flex: 1 1 auto;
    padding: 0 24rem;

    h1 {
      margin: 24rem 0 16rem 0;
      font-size: 20rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #262626;
    }

    h2 {
      margin-top: 16rem;
      font-size: 16rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #262626;
      line-height: 40rem;
    }

    p {
      font-size: 12rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #555555;
      line-height: 28rem;
    }

    table {
      margin-top: 16rem;
      width: 100%;
      border-left: 1rem solid #eee;
      border-top: 1rem solid #eee;

      th {
        font-size: 12rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #262626;
        height: 50rem;
        line-height: 50rem;
        background: #f7f7f7;
        border-right: 1rem solid #eee;
        border-bottom: 1rem solid #eee;
      }

      td {
        padding: 0 36rem;
        height: 50rem;
        line-height: 50rem;
        font-size: 12rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #555555;
        border-right: 1rem solid #eee;
        border-bottom: 1rem solid #eee;
      }
    }

    .code {
      padding: 24rem;
      background: #fafafa;
      font-size: 12rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #555555;
      line-height: 24rem;
    }
  }

  .link {
    color: #ff6932 !important;
  }
}

@keyframes leftFloat {
  0% {
    left: -200rem;
  }
  50% {
    left: -226rem;
  }
  100% {
    left: -200rem;
  }
}

@keyframes leftFloat1 {
  0% {
    left: 0rem;
  }
  50% {
    left: 0rem;
  }
  100% {
    left: 0rem;
  }
}
</style>
