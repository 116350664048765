<template>
    <div class="api">
        <div class="debug_left">
            <div class="debug_form">
                <div class="debug_label">返回格式(data_type):</div>
                <div class="debug_value">
                    <el-input disabled value="json"></el-input>
                </div>
            </div>
            <div class="debug_form">
                <div class="debug_label">API名称(type):</div>
                <div class="debug_value">
                    <el-select v-model="apiName" @change="changeApi" style="width: 100%">
                        <el-option v-for="(api,index) in apiList" :key="index" :value="api.apiName"
                                   :label="api.apiName"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="debug_form">
                <div class="debug_label">请求域名:</div>
                <div class="debug_value">
                    <el-input disabled :value="baseUrl"></el-input>
                </div>
            </div>
            <div class="debug_form">
                <div class="debug_label">请求url:</div>
                <div class="debug_value">
                    <el-input disabled :value="apiUrl"></el-input>
                </div>
            </div>
            <div class="debug_form">
                <div class="debug_label">appId:</div>
                <div class="debug_value">
                    <el-input v-model="appId" placeholder="请输入appId值"></el-input>
                </div>
            </div>
            <div class="debug_form">
                <div class="debug_label">appSecret:</div>
                <div class="debug_value">
                    <el-input v-model="appSecret" placeholder="请输入appSecret值"></el-input>
                </div>
            </div>
            <div class="debug_form">
                <div class="debug_label">timestamp:</div>
                <div class="debug_value">
                    <el-input disabled :value="requestParamShow.timestamp"></el-input>
                </div>
            </div>
            <div class="debug_form" v-for="(param, index) in apiParams" :key="index">
                <div class="debug_label">{{ param }}:</div>
                <div class="debug_value">
                    <el-input v-model="requestParam[param]" :placeholder="'请输入'+param+'值'"></el-input>
                </div>
            </div>
            <div class="debug_form">
                <div class="debug_label"></div>
                <div class="debug_value">
                    <el-button type="primary" @click="test">提交测试</el-button>
                    <el-button @click="signature">查看签名</el-button>
                </div>
            </div>
        </div>
        <div class="debug_right">
            <div class="debug_request">
                <div class="debug_title">API请求参数(API Request):</div>
                <div class="debug_content">
                    {{ requestParamShow }}
                </div>
            </div>
            <div class="debug_response">
                <div class="debug_title">API返回结果(API Response):</div>
                <div class="debug_content">
                    {{ responseShow }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {generateSign} from '../../utils/index'
import moment from "moment"

export default {
    name: "Api",
    data() {
        return {
            baseUrl: 'https://cloud.kuaimai.com',
            // baseUrl: 'http://cloud-test.kuaimai.com',
            appId: '',
            appSecret: '',
            apiList: [], // api列表，接口获取
            apiName: '', // 选择的API-名称
            apiUrl: '', // 选择的API-url
            apiParams: [], // 所有请求参数数组
            requestParam: {}, // 左侧请求参数
            requestParamShow: {}, // 右侧请求参数展示
            responseShow: null, // 右侧返回数据展示
        };
    },
    mounted() {
        this.getApiList()
    },
    destroyed() {
    },
    methods: {
        getApiList() {
            axios({
                method: 'post',
                url: this.baseUrl + '/open/apiList',
                data: {},
            }).then(res => {
                this.apiList = res.data.data
            })
        },
        changeApi(value) {
            this.apiList.map(api => {
                if (api.apiName === value) {
                    this.apiUrl = api.apiUrl
                    this.apiParams = api.apiParams.split(',')
                    this.requestParam = {}
                }
            })
        },
        test() {
            if (!this.apiName) {
                this.$message.warning('请选择API名称！')
                return;
            }

            let param = {
                appId: this.appId,
                timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
                ...this.requestParam
            };
            let {sign} = generateSign(param, this.appSecret)

            param.sign = sign
            this.requestParamShow = param

            axios({
                method: 'post',
                url: this.baseUrl + this.apiUrl,
                data: this.requestParamShow
            }).then(res => {
                this.responseShow = res.data
            })
        },
        signature() {
            if (!this.apiName) {
                this.$message.warning('请选择API名称！')
                return;
            }

            let param = {
                appId: this.appId,
                timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
                ...this.requestParam
            };
            let {strSignData, sign} = generateSign(param, this.appSecret)

            param.sign = sign
            // this.requestParamShow = param

            this.$alert(
                `<strong><i>加密前的签名：</i></strong><br>${strSignData}<br><br><strong><i>加密后的签名：</i></strong><br>${sign}`,
                '查看签名', {
                    dangerouslyUseHTMLString: true
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.api {
  flex-direction: row;
  font-size: 16rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  .debug_left {
    flex: 0 0 50%;

    .debug_form {
      margin-bottom: 16rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .debug_label {
        flex: 0 0 38%;
        text-align: right;
      }

      .debug_value {
        flex: 0 0 58%;
      }
    }
  }

  .debug_right {
    flex: 0 0 40%;

    .debug_title {
      font-weight: 600;
      font-size: 14rem;
      margin: 16rem 0 8rem 0;
    }

    .debug_request {

      .debug_content {
        height: 200rem;
        border: 1rem solid #ccc;
        overflow: auto;
        padding: 16rem;
      }
    }

    .debug_response {

      .debug_content {
        height: 400rem;
        border: 1rem solid #ccc;
        overflow: auto;
        padding: 16rem;
      }
    }
  }
}
</style>
