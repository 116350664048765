<template>
    <div class="cloud_printer">
        <div class="banner">
            <div class="main_content_1100">
                <div class="banner_title">快麦云打印机</div>
                <div class="banner_text">全系列产品线 优选硬件配置</div>
            </div>
        </div>
        <div class="printer_main">
            <div class="main_content_1100">
                <div class="printer_main_title">云标签打印机</div>
                <div class="printer_list">
                    <div class="printer_item printer_item_half printer_item_bg1">
                        <div class="printer_title">4寸系列云标签打印机</div>
                        <div class="printer_text">打印宽度：27-110mm</div>
                        <div class="printer_tag">
                            <div class="tag_item" @click="$router.push('/productCenter/WiFi/KM118')">KM-118DW</div>
                            <div class="tag_item">KM-118DG</div>
                        </div>
                    </div>
                    <div class="printer_item printer_item_half printer_item_bg2">
                        <div class="printer_title">3寸系列云标签打印机</div>
                        <div class="printer_text">打印宽度：27-80mm</div>
                        <div class="printer_tag">
                            <div class="tag_item" @click="$router.push('/productCenter/WiFi/KM118')">KM-118MW</div>
                        </div>
                    </div>
                    <div class="printer_item printer_item_half printer_item_bg3">
                        <div class="printer_title">2寸系列云标签打印机</div>
                        <div class="printer_text">打印宽度：27-58mm</div>
                        <div class="printer_tag">
                            <div class="tag_item" @click="$router.push('/productCenter/label/E20W')">E20W</div>
                        </div>
                    </div>
                    <div class="printer_item printer_item_half printer_item_bg4">
                        <div class="printer_title">便携云标签打印机</div>
                        <div class="printer_text">打印宽度：max 104mm</div>
                        <div class="printer_tag">
                            <div class="tag_item" @click="$router.push('/productCenter/label/SL410')">SL410</div>
                            <div class="tag_item" @click="$router.push('/productCenter/label/UL410')">UL410</div>
                        </div>
                    </div>
                </div>

                <div class="printer_main_title">云面单打印机</div>
                <div class="printer_list">
                    <div class="printer_item printer_item_half printer_item_bg5">
                        <div class="printer_title">一联单桌面云面单打印机</div>
                        <div class="printer_text">打印宽度：27-80mm</div>
                        <div class="printer_tag">
                            <div class="tag_item" @click="$router.push('/productCenter/faceSheet/KM218MGD')">拼多多版KM-218MGD</div>
                            <div class="tag_item" @click="$router.push('/productCenter/faceSheet/SC318')">菜鸟版SC318</div>
                            <div class="tag_item" @click="$router.push('/productCenter/4G/SX320')">SX320</div>
                            <div class="tag_item">KM-202MG</div>
                            <div class="tag_item">菜鸟版KM-202MGC</div>
                            <div class="tag_item">菜鸟版KM-202MGY</div>
                            <div class="tag_item">拼多多版KM-202MGD</div>
                        </div>
                    </div>
                    <div class="printer_item printer_item_half printer_item_bg6">
                        <div class="printer_title">便携云面单打印机</div>
                        <div class="printer_text">打印宽度：32-72mm</div>
                        <div class="printer_tag">
                            <div class="tag_item" @click="$router.push('/productCenter/faceSheet/SL310')">SL310</div>
                        </div>
                    </div>
                </div>

                <div class="printer_main_title">云小票打印机</div>
                <div class="printer_list">
                    <div class="printer_item printer_item_bg7">
                        <div class="printer_title">58mm桌面云小票打印机</div>
                        <div class="printer_text">打印宽度：58mm</div>
                        <div class="printer_tag">
                            <div class="tag_item" @click="$router.push('/productCenter/WiFi/DP158')">DP158</div>
                            <div class="tag_item" @click="$router.push('/productCenter/WiFi/DP358')">DP358系列</div>
                            <div class="tag_item" @click="$router.push('/productCenter/4G/DP458')">DP458系列</div>
                            <div class="tag_item" @click="$router.push('/productCenter/label/E20W')">E20W</div>
                            <div class="tag_item" @click="$router.push('/productCenter/ticket/DP058E')">饿了么DP058E</div>
                            <div class="tag_item">饿了么DP058G</div>
                        </div>
                    </div>
                    <div class="printer_item printer_item_bg8">
                        <div class="printer_title">便携云小票打印机</div>
                        <div class="printer_text">打印宽度：52-104mm</div>
                        <div class="printer_tag">
                            <div class="tag_item" @click="$router.push('/productCenter/label/SL410')">SL410</div>
<!--                            <div class="tag_item">佳博机器WiFi</div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PrinterList",
    data() {
        return {};
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.cloud_printer {
  .banner {
    height: 460rem;
    background: url("./images/banner.png") center / 1920rem 460rem no-repeat;

    .banner_title {
      padding-top: 214rem;
      font-size: 40rem;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: 700;
      color: #FD6520;
      line-height: 58rem;
    }

    .banner_text {
      margin-top: 12rem;
      font-size: 24rem;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #0D0D2F;
      line-height: 35rem;
    }
  }

  .printer_main {
    background: #F5F5F5;
    padding-bottom: 72rem;

    .printer_main_title {
      padding-top: 72rem;
      font-size: 40rem;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: 700;
      color: #FD6520;
      line-height: 58rem;
    }

    .printer_list {
      margin-top: 20rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .printer_item {
        margin-bottom: 20rem;
        flex: 0 0 100%;
        min-height: 246rem;
        background: #FFFFFF;
        border-radius: 20rem;
        padding: 27rem 0 0 31rem;
        box-sizing: border-box;

        &.printer_item_half {
          flex: 0 0 540rem;

          .printer_tag {
            margin-top: 30rem;
            width: 280rem;
          }
        }

        &.printer_item_bg1 {
          background: #FFFFFF url("./images/printer-1.png") center right / 540rem 246rem no-repeat;
        }

        &.printer_item_bg2 {
          background: #FFFFFF url("./images/printer-2.png") center right / 540rem 246rem no-repeat;
        }

        &.printer_item_bg3 {
          background: #FFFFFF url("./images/printer-3.png") center right / 540rem 246rem no-repeat;
        }

        &.printer_item_bg4 {
          background: #FFFFFF url("./images/printer-4.png") center right / 540rem 246rem no-repeat;
        }

        &.printer_item_bg5 {
          background: #FFFFFF url("./images/printer-5.png") center right / 540rem 246rem no-repeat;
        }

        &.printer_item_bg6 {
          background: #FFFFFF url("./images/printer-6.png") center right / 540rem 246rem no-repeat;
        }

        &.printer_item_bg7 {
          background: #FFFFFF url("./images/printer-7.png") center right / 1100rem 246rem no-repeat;
        }

        &.printer_item_bg8 {
          background: #FFFFFF url("./images/printer-8.png") center right / 1100rem 246rem no-repeat;
        }

        .printer_title {
          font-size: 20rem;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: 700;
          color: #262644;
          line-height: 29rem;
        }

        .printer_text {
          margin-top: 2rem;
          font-size: 14rem;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 350;
          color: #666666;
          line-height: 22rem;
        }

        .printer_tag {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-top: 100rem;

          .tag_item {
            margin: 0 15rem 15rem 0;
            padding: 0 16rem;
            height: 32rem;
            background: #FFFFFF;
            border-radius: 32rem;
            border: 1rem solid #FD6520;
            font-size: 14rem;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 350;
            color: #FD6520;
            line-height: 32rem;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
