<template>
    <div class="document">
        <div class="main_content">
            <div class="document_menu">
                <div class="document_title">目录</div>
                <div class="document_menu_list">
                    <div class="document_menu_item" v-for="(menu, index) in menuList" :key="index">
                        <div class="document_menu_first" :class="{selected: openMenuIndex === menu.id}"
                             @click="toggleMenu(menu)">
                            {{ menu.label }}
                            <i class="el-icon-caret-right" :class="{menu_icon_open: openMenuIndex === menu.id}"></i>
                        </div>
                        <div class="document_menu_box" v-show="openMenuIndex === menu.id">
                            <div class="document_menu_second" :class="{selected: mdSelected === subMenu.id}"
                                 v-for="(subMenu, subIndex) in menu.children" :data-url="subMenu.url"
                                 :key="subIndex" @click="openMD(subMenu)">
                                {{ subMenu.label }}
                            </div>
                        </div>
                    </div>
                    <!--                    <div class="document_menu_item">
                                            <div class="document_menu_first" :class="{selected: openMenuIndex === 'java'}"
                                                 @click="openJava">
                                                HTTP协议对接
                                            </div>
                                        </div>-->
                    <div class="document_menu_item">
                        <div class="document_menu_first" :class="{selected: openMenuIndex === 'debugger'}"
                             @click="openDebugger">
                            调试工具
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="openMenuIndex === 'java'" class="document_main">
                <javaApi></javaApi>
            </div>
            <div v-else-if="openMenuIndex === 'debugger'" class="document_main">
                <debuggerComp></debuggerComp>
            </div>
            <div v-else class="document_main markdown-body" v-html="documentHtml"></div>
        </div>

        <imageViewer v-show="showImg" :urlList="[imageUrl]" :on-close="closeViewer"></imageViewer>
    </div>
</template>

<script>
import {marked} from 'marked';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css'
import javaApi from './api-document.vue'
import debuggerComp from './debugger.vue'
import imageViewer from './image-viewer.vue'

export default {
    name: "Document",
    components: {javaApi, debuggerComp, imageViewer},
    data() {
        return {
            openMenuIndex: '',
            menuList: [],
            documentHtml: '',
            mdSelected: '',
            showImg: false,
            imageUrl: '',
        };
    },
    mounted() {
        this.menuList = [
            {
                label: 'JS SDK',
                id: 'js',
                children: [
                    {
                        label: 'js-sdk',
                        id: 'jssdk',
                        url: 'https://kuaimai-public.oss-cn-shanghai.aliyuncs.com/public/document/CLOUD_PRINT.md'
                    }
                ]
            },
            {
                label: '移动端',
                id: 'mobile',
                children: [
                    {
                        label: 'iOS',
                        id: 'mobilesdk',
                        url: 'https://kuaimai-public.oss-cn-shanghai.aliyuncs.com/public/document/CLOUD_PRINT(iOS).md'
                    },
                    {
                        label: 'Android',
                        id: 'mobilesdk2',
                        url: 'https://kuaimai-public.oss-cn-shanghai.aliyuncs.com/public/document/CLOUD_PRINT(安卓).md'
                    }
                ]
            },
            {
                label: 'HTTP协议对接',
                id: 'http',
                children: [
                    {
                        label: 'HTTP协议对接',
                        id: 'httpsdk',
                        url: 'https://kuaimai-public.oss-cn-shanghai.aliyuncs.com/public/document/快麦云打印对外开放接口.md'
                    },
                ]
            }
        ]

        this.openMenuIndex = 'http'
        this.mdSelected = 'httpsdk'
        this.initMD('https://kuaimai-public.oss-cn-shanghai.aliyuncs.com/public/document/快麦云打印对外开放接口.md')
    },
    methods: {
        openImageViewer() {
            document.getElementsByClassName('markdown-body')[0].querySelector('img').addEventListener('click', (e) => {
                this.imageUrl = e.target.src
                this.showImg = true
            })
        },
        closeViewer() {
            this.showImg = false
        },
        toggleMenu(menu) {
            if (this.openMenuIndex === menu.id) {
                this.openMenuIndex = ''
            } else {
                this.openMenuIndex = menu.id
            }
        },
        openMD(menu) {
            this.mdSelected = menu.id
            this.initMD(menu.url)
        },
        async initMD(url) {
            const response = await fetch(url + '?timestamp=' + new Date().getTime());
            const text = await response.text();

            this.documentHtml = marked.parse(text, {
                renderer: new marked.Renderer(),
                highlight: (code, lang) => {
                    const language = hljs.getLanguage(lang) ? lang : 'plaintext'
                    return hljs.highlight(code, {language}).value
                },
                langPrefix: 'language-',
                pedantic: false,
                gfm: true,
                breaks: false,
                sanitize: false,
                smartLists: true,
                smartypants: false,
                xhtml: false
            })

            this.$nextTick(() => {
                this.openImageViewer();
            })

        },
        openJava() {
            this.openMenuIndex = 'java'
        },
        openDebugger() {
            this.openMenuIndex = 'debugger'
        },
    },
};
</script>

<style lang="scss" scoped>
.document {
  background: #F5F5F5;
  padding: 34rem 0 56rem;

  .main_content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .document_menu {
    margin-right: 24rem;
    flex: 0 0 257rem;
    background: #FFFFFF;
    border-radius: 6rem;
    border: 1rem solid #CCCCCC;

    .document_title {
      height: 66rem;
      line-height: 66rem;
      padding: 0 20rem;
      font-size: 19rem;
      font-family: Source Han Sans, Source Han Sans;
      font-weight: 500;
      color: #191938;
    }

    .document_menu_list {
      .document_menu_item {
        .document_menu_first {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 0 20rem;
          height: 46rem;
          font-size: 19rem;
          font-family: Source Han Sans, Source Han Sans;
          font-weight: 500;
          color: #191938;
          line-height: 46rem;
          background: #F5F5F5;
          cursor: pointer;

          &.selected {
            color: #FF4C02;
          }

          .menu_icon_open {
            transform: rotate(90deg);
          }
        }

        .document_menu_box {
          padding-left: 30rem;
        }

        .document_menu_second {
          padding-left: 12rem;
          height: 40rem;
          font-size: 17rem;
          font-family: Source Han Sans, Source Han Sans;
          font-weight: 500;
          color: #191938;
          line-height: 40rem;
          cursor: pointer;

          &:not(:last-child) {
            border-bottom: 1rem solid #D8D8D8;
          }

          &.selected {
            color: #FF4C02;
          }
        }
      }
    }
  }

  .document_main {
    flex: 1;
    overflow: hidden;
    background: #FFFFFF;
    border-radius: 6rem;
    border: 1rem solid #CCCCCC;
    padding: 35rem;
  }
}
</style>
