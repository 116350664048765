<template>
    <div class="cloud_printer">
        <div class="top_container">
            <div class="main_content_1100">
                <div class="top_title">什么是云打印？</div>
                <div class="top_text">
                    云打印是一套基于硬件打印机和软件服务的解决方案；打印机通过WiFi、4G、以太网入网，打印数据通过云端下发，即可实现异地、多人远程打印。云打印不限打印距离、不限使用人数，不限使用场景，广泛应用于各个有打印需求的场景，如快递物流面单、标签、小票打印等，适用于零售、电商、线下批发、餐饮等各行业。
                </div>
                <div class="top_img_box">
                    <img class="top_img" src="./images/top_img.svg" alt="img"/>
                </div>
                <div class="top_title top_title2">云打印的优势</div>
                <div class="top_contrast">
                    <hr class="contrast_line contrast_line1"/>
                    <hr class="contrast_line contrast_line2"/>
                    <hr class="contrast_line contrast_line3"/>
                    <hr class="contrast_line contrast_line4"/>
                    <hr class="contrast_line contrast_line5"/>
                    <div class="top_col top_col1">
                        <div class="col_title">打印参数</div>
                        <div class="col_text">打印最远距离</div>
                        <div class="col_text">共享打印</div>
                        <div class="col_text">安装方式</div>
                        <div class="col_text">支持平台</div>
                        <div class="col_text">远程售后</div>
                    </div>
                    <div class="top_col">
                        <div class="col_title">USB打印</div>
                        <div class="col_text">1m</div>
                        <div class="col_text">受限</div>
                        <div class="col_text">安装驱动</div>
                        <div class="col_text">Windows</div>
                        <div class="col_text">不支持</div>
                    </div>
                    <div class="top_col">
                        <div class="col_title">蓝牙打印</div>
                        <div class="col_text">10m</div>
                        <div class="col_text">受限</div>
                        <div class="col_text">安装驱动/应用</div>
                        <div class="col_text">Windows / 移动端</div>
                        <div class="col_text">不支持</div>
                    </div>
                    <div class="contrast_cloud">
                        <hr class="contrast_cloud_line contrast_cloud_line1"/>
                        <hr class="contrast_cloud_line contrast_cloud_line2"/>
                        <hr class="contrast_cloud_line contrast_cloud_line3"/>
                        <hr class="contrast_cloud_line contrast_cloud_line4"/>
                        <hr class="contrast_cloud_line contrast_cloud_line5"/>
                        <div class="contrast_cloud_title">云打印</div>
                        <div class="contrast_cloud_text">不限距离</div>
                        <div class="contrast_cloud_text">不限人数</div>
                        <div class="contrast_cloud_text">免安装驱动</div>
                        <div class="contrast_cloud_text">Windows / Mac / Linux / iOS / Android</div>
                        <div class="contrast_cloud_text" style="line-height: 40rem;">
                            支持远程固件升级；支持远程设备监控和管理；支持远程调试。
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cloud_main_container">
            <div class="main_content_1100">
                <div class="main_title">快麦云打印服务</div>
                <div class="main_text">
                    快麦云打印是基于快麦云打印机和快麦云软硬件为一体的解决方案；快麦云打印机联网后设备上云，SaaS软件方通过快麦API接口对接快麦云，SaaS终端用户打印时信息通过快麦云端下发给到打印机，打印数据云端保存、云端下发，保证打印不漏单。
                </div>
                <img class="cloud_server_img" src="./images/cloud_server.svg" alt="cloud_server">

                <div class="main_title main_title_top">可视化模板编辑服务</div>
                <div class="cloud_container">
                    <div class="cloud_img_box">
                        <img class="cloud_img1_1" src="./images/cloud1.svg" alt="cloud">
                        <img class="cloud_img1_2" src="./images/cloud1.svg" alt="cloud">
                    </div>
                    <div class="cloud_text_box">
                        <div class="cloud_text_box_title">打印模板生成</div>
                        <div class="cloud_text_box_text">
                            支持tspl、cpcl、Esc等指令进行模板编程；<br/>
                            支持xml语言进行模板编程；<br/>
                            支持SaaS方标签、小票可视化模板编辑；<br/>
                            支持SaaS方集成上述编辑组件；实现SaaS方终端用户标签、小票可视化编辑。
                        </div>
                        <div class="cloud_text_box_title">模板动态渲染</div>
                        <div class="cloud_text_box_text">
                            提供云端渲染API；<br/>
                            云端动态渲染下发。
                        </div>
                        <a class="cloud_btn" @click="openNew('http://admin.iot.kuaimai.com/')">体验模板编辑服务</a>
                    </div>
                </div>

                <div class="main_title main_title_top">快麦IoT云</div>
                <div class="cloud_container">
                    <div class="cloud_text_box">
                        <div class="cloud_text_box_title">稳定云服务</div>
                        <div class="cloud_text_box_text">
                            去中心化，无单点依赖，连续1285天无宕机；<br/>
                            24H在线技术支持；<br/>
                            故障24H内修复。
                        </div>
                        <div class="cloud_text_box_title">海量设备连接能力</div>
                        <div class="cloud_text_box_text">支持千万台设备规模同时在线。</div>
                        <div class="cloud_text_box_title">开放API接口</div>
                        <div class="cloud_text_box_text">
                            提供标准的http协议对接，支持多端跨平台调用；<br/>
                            提供多种语言对接SDK。
                        </div>
                    </div>
                    <div class="cloud_img_box cloud_img_box_bg">
                        <img class="cloud_img2" src="./images/cloud2.svg" alt="cloud">
                    </div>
                </div>

                <div class="main_title main_title_top">云打印API介绍</div>
                <div class="dev_api">
                    <div class="dev_sub_title">API能力</div>
                    <div class="dev_row">
                        <div class="dev_col">
                            <div class="dev_col_btn">
                                <img class="dev_col_btn_img" src="../openDev/images/1.jpg" alt="">
                                打印机管理
                            </div>
                        </div>
                        <div class="dev_col dev_col2">
                            <div class="dev_col_btn">
                                <img class="dev_col_btn_img" src="../openDev/images/2.jpg" alt="">
                                打印任务管理
                            </div>
                        </div>
                        <div class="dev_col">
                            <div class="dev_col_btn">
                                <img class="dev_col_btn_img" src="../openDev/images/3.jpg" alt="">
                                图片打印
                            </div>
                        </div>
                    </div>
                    <div class="dev_sub_title">对接平台支持</div>
                    <div class="dev_row dev_row2">
                        <div class="dev_col">
                            <div class="dev_col_title">PC端WEB对接</div>
                            <div class="dev_col_text">
                                提供jssdk，调用js接口<br/>进行打印对接。
                            </div>
                        </div>
                        <div class="dev_col dev_col2">
                            <div class="dev_col_title">移动端应用对接</div>
                            <div class="dev_col_text">
                                提供安卓端sdk，iOS端<br/>sdk对接。
                            </div>
                        </div>
                        <div class="dev_col">
                            <div class="dev_col_title">PC端应用对接</div>
                            <div class="dev_col_text">
                                支持所有应用开发语言<br/>
                                http协议对接；C#、C++、<br/>
                                python等其他语言sdk开发中。
                            </div>
                        </div>
                    </div>
                    <div class="dev_sub_title">云打印API优势</div>
                    <div class="dev_row dev_row3">
                        <div class="dev_col">
                            <div class="dev_col_header">云打印功能</div>
                            <div class="dev_col_item">字号</div>
                            <div class="dev_col_item">字体</div>
                            <div class="dev_col_item">旋转打印</div>
                            <div class="dev_col_item">整图打印</div>
                            <div class="dev_col_item">模板渲染</div>
                        </div>
                        <div class="dev_col dev_col2">
                            <div class="dev_col_header">快麦云打印</div>
                            <div class="dev_col_item">支持所有字号</div>
                            <div class="dev_col_item">支持所有字体</div>
                            <div class="dev_col_item">支持90°、180°、270°所有垂直旋转</div>
                            <div class="dev_col_item">支持整图（50KB以内）下发打印</div>
                            <div class="dev_col_item">按可视化模版进行渲染，不需要了解指令</div>
                        </div>
                        <div class="dev_col">
                            <div class="dev_col_header">其他云打印</div>
                            <div class="dev_col_item">仅支持打印机内嵌字号，易出现打印锯齿问题</div>
                            <div class="dev_col_item">仅支持单一字体</div>
                            <div class="dev_col_item">不支持</div>
                            <div class="dev_col_item">不支持</div>
                            <div class="dev_col_item">需要自己拼指令或类指令xml</div>
                        </div>
                    </div>
                </div>

                <div class="main_title main_title_top">快麦云打印机</div>
                <div class="cloud_container">
                    <div class="cloud_img_box cloud_img_box_bg2">
                        <img class="cloud_img3" src="../home/images/118D&118DW.svg" alt="cloud">
                    </div>
                    <div class="cloud_text_box">
                        <div class="cloud_text_box_title">全系列产品线</div>
                        <div class="cloud_text_box_text">
                            涵盖面单、标签、小票等各类云打印机；<br/>
                            支持WiFi、4G 2种网络连接方式；<br/>
                            支持语音、切刀等各类需求。
                        </div>
                        <div class="cloud_text_box_title">优选硬件配置</div>
                        <div class="cloud_text_box_text">
                            提供单频、双频WiFi模组选配；<br/>
                            高品质打印头实现清晰打印。
                        </div>
                        <div class="cloud_text_box_title">设备远程售后</div>
                        <div class="cloud_text_box_text">
                            支持远程固件升级；<br/>
                            支持远程设备监控和管理；<br/>
                            支持远程调试。
                        </div>
                    </div>
                </div>

                <div class="main_title main_title_top">对接流程</div>
                <div class="cloud_process">
                    <div class="cloud_process_item">
                        订购试用<br/>
                        云打印机
                    </div>
                    <img src="./images/arrow.png" alt="arrow"/>
                    <div class="cloud_process_item">
                        注册开放<br/>
                        平台账号
                    </div>
                    <img src="./images/arrow.png" alt="arrow"/>
                    <div class="cloud_process_item">
                        免费<br/>
                        API对接
                    </div>
                    <img src="./images/arrow.png" alt="arrow"/>
                    <div class="cloud_process_item">
                        上线使用
                    </div>
                </div>

                <div class="main_title main_title_top">我们的优势</div>
                <div class="dash_container">
                    <div class="dash_item">
                        <img class="dash_item_logo" src="./images/advantage1.svg" alt="advantage"/>
                        <div class="dash_item_title">稳定</div>
                        <div class="dash_item_text">
                            公司产品累计用户量500W+；<br/>
                            双11同时在线用户数100W+；<br/>
                            云服务已连续1285天无宕机。
                        </div>
                    </div>
                    <div class="dash_item">
                        <img class="dash_item_logo" src="./images/advantage2.svg" alt="advantage"/>
                        <div class="dash_item_title">成熟</div>
                        <div class="dash_item_text">
                            稳定运行1925天；<br/>
                            累计激活设备数50W+；<br/>
                            累计服务用户数40W+。
                        </div>
                    </div>
                    <div class="dash_item">
                        <img class="dash_item_logo" src="./images/advantage3.svg" alt="advantage"/>
                        <div class="dash_item_title">技术支持</div>
                        <div class="dash_item_text">
                            支持多端跨平台调用；<br/>
                            提供多种语言对接SDK；<br/>
                            24H在线API对接指导。
                        </div>
                    </div>
                    <div class="dash_item">
                        <img class="dash_item_logo" src="./images/advantage4.svg" alt="advantage"/>
                        <div class="dash_item_title">售后服务</div>
                        <div class="dash_item_text">
                            专业技术和售后团队24H在线技术支持；<br/>
                            云服务故障24H内修复。
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CloudPrinter",
    data() {
        return {};
    },
    methods: {
        openNew(url) {
            window.open(url);
        },
    },
};
</script>

<style lang="scss" scoped>
.cloud_printer {
    .top_container {
        height: 1390rem;
        background: #E2F1FA;

        .top_title {
            padding-top: 176rem;
            font-size: 40rem;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: 700;
            color: #191938;
            line-height: 58rem;
        }

        .top_title2 {
            padding-top: 0;
            margin-top: 64rem;
        }

        .top_text {
            margin-top: 14rem;
            font-size: 18rem;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #3D3D3D;
            line-height: 26rem;
        }

        .top_img_box {
            margin-top: 64rem;
            height: 416rem;
            background: #FFFFFF;
            border-radius: 20rem;
            border: 1rem dashed #AAAAAA;

            .top_img {
                display: block;
                height: 368rem;
                margin: 25rem auto 0;
            }
        }

        .top_contrast {
            margin-top: 36rem;
            position: relative;
            height: 343rem;
            background: #FFFFFF;
            border-radius: 20rem;
            border: 1rem dashed #AAAAAA;
            display: flex;
            flex-direction: row;

            .contrast_line {
                position: absolute;
                left: 0;
                width: 100%;
                height: 0;
                border: 0;
                border-top: 1rem dashed #AAAAAA;
                margin: 0;

                &.contrast_line1 {
                    top: 80rem;
                }

                &.contrast_line2 {
                    top: 132rem;
                }

                &.contrast_line3 {
                    top: 184rem;
                }

                &.contrast_line4 {
                    top: 236rem;
                }

                &.contrast_line5 {
                    top: 288rem;
                }
            }

            .top_col {
                display: flex;
                flex-direction: column;
                margin-left: 58rem;

                &:not(:first-child) {
                    margin-left: 84rem;
                }

                .col_title {
                    height: 80rem;
                    font-size: 18rem;
                    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                    font-weight: 700;
                    color: #FD6520;
                    line-height: 80rem;
                }

                &.top_col1 .col_text {
                    color: #666666;
                }

                .col_text {
                    height: 52rem;
                    font-size: 18rem;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    line-height: 52rem;
                }
            }

            .contrast_cloud {
                position: absolute;
                top: -27rem;
                right: 50rem;
                width: 433rem;
                height: 404rem;
                background: #191938;
                border-radius: 20rem;
                padding-left: 51rem;
                box-sizing: border-box;

                .contrast_cloud_line {
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 0;
                    border: 0;
                    border-top: 1rem dashed #FD6520;
                    margin: 0;

                    &.contrast_cloud_line1 {
                        top: 107rem;
                    }

                    &.contrast_cloud_line2 {
                        top: 159rem;
                    }

                    &.contrast_cloud_line3 {
                        top: 211rem;
                    }

                    &.contrast_cloud_line4 {
                        top: 263rem;
                    }

                    &.contrast_cloud_line5 {
                        top: 315rem;
                    }
                }

                .contrast_cloud_title {
                    height: 108rem;
                    font-size: 36rem;
                    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                    font-weight: 700;
                    color: #FD6520;
                    line-height: 120rem;
                }

                .contrast_cloud_text {
                    height: 52rem;
                    font-size: 18rem;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #FD6520;
                    line-height: 52rem;
                }
            }
        }
    }

    .cloud_main_container {
        background: #F5F5F5;
        padding: 54rem 0 122rem 0;

        .main_title {
            font-size: 40rem;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: 700;
            color: #FD6520;
            line-height: 58rem;
        }

        .main_text {
            margin-top: 14rem;
            font-size: 18rem;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #3D3D3D;
            line-height: 26rem;
        }

        .cloud_server_img {
            display: block;
            margin: 17rem auto 0;
        }

        .main_title_top {
            margin-top: 50rem;
        }

        .cloud_container {
            margin-top: 20rem;
            height: 430rem;
            background: #FFFFFF;
            border-radius: 20rem;
            display: flex;
            flex-direction: row;

            .cloud_img_box {
                flex: 1 1 auto;
                display: flex;
                flex-direction: row;
                align-items: center;

                &.cloud_img_box_bg {
                    background: #B3E0FF;
                }

                &.cloud_img_box_bg2 {
                    position: relative;
                    background: #F0F0F0;
                    overflow: hidden;
                }

                .cloud_img1_1 {
                    margin-left: 34rem;
                }

                .cloud_img1_2 {
                    margin-left: -162rem;
                }

                .cloud_img2 {
                    display: block;
                    margin: 0 auto;
                }

                .cloud_img3 {
                    position: absolute;
                    width: 800rem;
                    left: -68rem;
                    top: -50rem;
                }
            }

            .cloud_text_box {
                flex: 0 0 473rem;
                padding: 42rem 70rem 0;
                box-sizing: border-box;

                .cloud_text_box_title {
                    font-size: 18rem;
                    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                    font-weight: 700;
                    color: #FD6520;
                    line-height: 26rem;
                }

                .cloud_text_box_text {
                    margin-top: 4rem;
                    margin-bottom: 30rem;
                    font-size: 16rem;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #191938;
                    line-height: 26rem;
                }

                .cloud_btn {
                    margin-top: -6rem;
                    display: block;
                    width: 177rem;
                    height: 46rem;
                    font-size: 16rem;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #FD6520;
                    line-height: 46rem;
                    border: 2rem solid #FD6520;
                    text-align: center;
                    cursor: pointer;
                    border-radius: 46rem;
                }
            }
        }

        .cloud_process {
            margin-top: 19rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .cloud_process_item {
                width: 147rem;
                height: 147rem;
                background: #262644;
                border-radius: 50%;
                color: #FFFFFF;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 18rem;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                text-align: center;

                line-height: 26rem;
            }
        }

        .dash_container {
            margin-top: 27rem;
            height: 260rem;
            background: #FFFFFF;
            border-radius: 20rem;
            border: 1rem dashed #AAAAAA;
            display: flex;
            flex-direction: row;

            .dash_item {
                padding-right: 20rem;
                display: flex;
                flex-direction: column;
                border-right: 1rem dashed #D8D8D8;

                &:last-child {
                    border-right: 0;
                }

                .dash_item_logo {
                    margin: 45rem 0 0 37rem;
                    width: 40rem;
                }

                .dash_item_title {
                    margin: 17rem 0 0 37rem;
                    font-size: 18rem;
                    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                    font-weight: 700;
                    color: #FD6520;
                    line-height: 26rem;
                }

                .dash_item_text {
                    margin: 5rem 0 0 37rem;
                    font-size: 16rem;
                    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
                    font-weight: 350;
                    color: #191938;
                    line-height: 26rem;
                }
            }
        }
    }

    .dev_api {
        margin-top: 20rem;
        padding: 35rem;
        background: #ffffff;
        border-radius: 20rem;
        box-sizing: border-box;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;

        .dev_sub_title {
            margin-bottom: 24rem;
            font-size: 24rem;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 350;
            color: #FD6520;
        }

        .dev_row {
            margin-bottom: 24rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .dev_col {
                flex: 0 0 30%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                &.dev_col2 {
                    flex: 0 0 40%;
                }

                .dev_col_btn {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 240rem;
                    height: 80rem;
                    border: 1px dotted #FD6520;
                    color: #000;
                    font-size: 20rem;
                    font-weight: 600;
                    border-radius: 12rem;

                    .dev_col_btn_img {
                        margin-right: 16rem;
                        height: 40rem;
                    }
                }

                .dev_col_title {
                    width: 230rem;
                    margin-bottom: 16rem;
                    color: #FD6520;
                    font-size: 18rem;
                    font-weight: 700;
                }

                .dev_col_text {
                    width: 230rem;
                    color: #333;
                    font-size: 14rem;
                    line-height: 24rem;
                }
            }
        }

        .dev_row2 {
            align-items: flex-start;

            .dev_col {
                border-right: 1px dashed #333;
                min-height: 110rem;
                justify-content: flex-start;

                &:last-child {
                    border-right: none;
                }
            }
        }

        .dev_row3 {
            border: 1px dotted #333;
            border-radius: 16rem;

            .dev_col {
                border-right: 1px dotted #333;

                &:last-child {
                    border-right: none;
                }

                .dev_col_header {
                    border-bottom: 1px dotted #333;
                    width: 100%;
                    height: 80rem;
                    line-height: 80rem;
                    font-size: 22rem;
                    font-weight: 600;
                    text-align: center;
                }

                .dev_col_item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-bottom: 1px dotted #333;
                    width: 100%;
                    height: 60rem;
                    font-size: 16rem;
                    text-align: center;

                    &:last-child {
                        border-bottom: none;
                    }
                }
            }

            .dev_col2 {
                .dev_col_header {
                    font-size: 28rem;
                    color: #FD6520;
                }

                .dev_col_item {
                    color: #FD6520;
                }
            }
        }
    }
}
</style>
