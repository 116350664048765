<template>
    <div class="solution">
        <div class="banner">
            <div class="main_content_1100">
                <div class="banner_title">快麦支持企业智能化经营</div>
            </div>
        </div>
        <div class="solution_main">
            <div class="main_content_1100">
                <div class="tab_menu">
                    <div class="tab_menu_item">测试1</div>
                    <div class="tab_menu_item selected">测试2</div>
                    <div class="tab_menu_item">测试3</div>
                </div>
                <div class="solution_main_title">场景痛点</div>
                <div class="solution_box solution_box_flex">
                    <div class="solution_box_item solution_box_item_row">
                        <img class="solution_box_img" src="./images/1.svg" alt="img"/>
                        <div class="solution_box_text">
                            <div class="solution_box_text_title">打印信号不稳定</div>
                            <div class="solution_box_text_text">
                                蓝牙受距离限制，易断开，<br/>
                                打印漏单影响商家出餐。
                            </div>
                        </div>
                    </div>
                    <div class="solution_box_item solution_box_item_row">
                        <img class="solution_box_img" src="./images/2.svg" alt="img"/>
                        <div class="solution_box_text">
                            <div class="solution_box_text_title">设备成本高</div>
                            <div class="solution_box_text_text">
                                普通外卖打印机需要依赖手机或者<br/>
                                电脑打印，开店成本高。
                            </div>
                        </div>
                    </div>
                    <div class="solution_box_item solution_box_item_row">
                        <img class="solution_box_img" src="./images/3.svg" alt="img"/>
                        <div class="solution_box_text">
                            <div class="solution_box_text_title">多店铺无法同时打印</div>
                            <div class="solution_box_text_text">
                                多个外卖平台店铺需要逐个连接机器<br/>
                                打印，无法同时接单。
                            </div>
                        </div>
                    </div>
                </div>
                <div class="solution_main_title">解决方案</div>
                <div class="solution_box solution_box_flex">
                    <div class="solution_box_item solution_box_item_row">
                        <img class="solution_box_img" src="./images/wifi-fill.svg" alt="img"/>
                        <div class="solution_box_text">
                            <div class="solution_box_text_title">WiFi/4G配网</div>
                            <div class="solution_box_text_text">
                                云打印机通过WiFi / 4G配网，<br/>
                                订单云端排队下发0漏单，<br/>
                                断电、断网自动补打。
                            </div>
                        </div>
                    </div>
                    <div class="solution_box_item solution_box_item_row">
                        <img class="solution_box_img" src="./images/printer-fill.svg" alt="img"/>
                        <div class="solution_box_text">
                            <div class="solution_box_text_title">自动接单/打印</div>
                            <div class="solution_box_text_text">
                                手机一次连接后即可自动接单、<br/>
                                自动打印，无需电脑/手机，<br/>
                                节省硬件成本。
                            </div>
                        </div>
                    </div>
                    <div class="solution_box_item solution_box_item_row">
                        <img class="solution_box_img" src="./images/store-3-fill.svg" alt="img"/>
                        <div class="solution_box_text">
                            <div class="solution_box_text_title">一机多店</div>
                            <div class="solution_box_text_text">
                                一台机器支持绑定多个平台、<br/>
                                同时下发顺序打印。
                            </div>
                        </div>
                    </div>
                </div>
                <div class="solution_main_title">选用打印机</div>
                <div class="printer_list">
                    <div class="printer_item printer_item_bg1">
                        <div class="printer_title">4寸系列云标签打印机</div>
                        <div class="printer_text">
                            WiFi+来单滴滴声<br/>
                            支持58mm热敏票据打印
                        </div>
                    </div>
                    <div class="printer_item printer_item_bg1">
                        <div class="printer_title">4寸系列云标签打印机</div>
                        <div class="printer_text">
                            WiFi+来单滴滴声<br/>
                            支持58mm热敏票据打印
                        </div>
                    </div>
                    <div class="printer_item printer_item_bg1">
                        <div class="printer_title">4寸系列云标签打印机</div>
                        <div class="printer_text">
                            WiFi+来单滴滴声<br/>
                            支持58mm热敏票据打印
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Solution",
    data() {
        return {};
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.solution {
  .banner {
    height: 460rem;
    background: url("./images/banner.png") center / 1920rem 460rem no-repeat;

    .banner_title {
      padding-top: 254rem;
      font-size: 40rem;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: 700;
      color: #FD6520;
      line-height: 58rem;
    }
  }

  .solution_main {
    padding-top: 44rem;
    background: #F5F5F5;
    padding-bottom: 72rem;

    .tab_menu {
      padding: 0 30rem;
      height: 60rem;
      background: #FFFFFF;
      border: 2rem solid #FD6520;
      border-radius: 60rem;
      display: flex;
      flex-direction: row;
      align-items: center;

      .tab_menu_item {
        padding: 8rem 20rem;
        text-align: center;
        font-size: 16rem;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #191938;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          color: #FD6520;
          transition: all 0.3s;
        }

        &.selected {
          background: #FD6520;
          border-radius: 60rem;
          color: #FFFFFF;
        }
      }
    }

    .solution_main_title {
      padding-top: 72rem;
      font-size: 40rem;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: 700;
      color: #FD6520;
      line-height: 58rem;
    }

    .solution_box_flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    .solution_box {
      margin-top: 20rem;
      padding: 35rem;
      background: #FFFFFF;
      border-radius: 20rem;
      border: 1rem dashed #AAAAAA;
      box-sizing: border-box;

      .solution_box_item {
        display: flex;

        &.solution_box_item_row {
          flex-direction: row;
          align-items: flex-start;
        }

        .solution_box_img {
          width: 40rem;
          margin: 0 25rem 12rem 0;
        }

        .solution_box_text {
          display: flex;
          flex-direction: column;

          .solution_box_text_title {
            font-size: 18rem;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: 700;
            color: #FD6520;
            line-height: 26rem;
          }

          .solution_box_text_text {
            margin-top: 5rem;
            font-size: 14rem;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 350;
            color: #191938;
            line-height: 22rem;
          }
        }
      }

      .solution_arrow_img {
        margin-top: 66rem;
        width: 75rem;
      }

      .solution_box_btn {
        margin: 42rem auto 0;
        width: 146rem;
        padding: 12rem 0;
        border: 2rem solid #FD6520;
        font-size: 18rem;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #FD6520;
        cursor: pointer;
        text-align: center;
        border-radius: 100rem;
      }
    }

    .printer_list {
      margin-top: 20rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .printer_item {
        margin-bottom: 20rem;
        flex: 0 0 350rem;
        height: 246rem;
        background: #FFFFFF;
        border-radius: 20rem;
        padding: 27rem 0 0 31rem;
        box-sizing: border-box;

        &.printer_item_bg1 {
          background: #FFFFFF url("./images/printer1.svg") center right / 540rem 246rem no-repeat;
        }

        .printer_title {
          font-size: 20rem;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: 700;
          color: #262644;
          line-height: 29rem;
        }

        .printer_text {
          margin-top: 2rem;
          font-size: 14rem;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 350;
          color: #666666;
          line-height: 22rem;
        }
      }
    }
  }
}
</style>
