<template>
    <div class="menus">
        <template v-for="menu in routes">
            <div
                v-show="!menu.hidden"
                class="menu-item"
                :class="{ actived: menu.path && selectedPath === menu.path, disabled: !menu.path }"
                :key="menu.path"
                @click="routeTo(menu.path)"
            >
                {{ menu.name }}
            </div>
        </template>
    </div>
</template>

<script>
import {routes} from "../../router";

export default {
    name: "Menus",
    data() {
        return {
            routes: routes,
            selectedPath: "",
        };
    },
    mounted() {
        this.selectedPath = this.$route.path;
        this.$router.afterEach((to) => {
            this.selectedPath = to.path;
        });
    },
    methods: {
        routeTo(route) {
            if (!route || route === this.selectedPath) {
                return;
            }
            this.$router.push(route);
            this.selectedPath = route;
        },
    },
};
</script>

<style lang="scss" scoped>
.menus {
  display: flex;
  flex-direction: row;

  .menu-item {
    font-size: 18rem;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    cursor: pointer;
    transition: all 0.3s;

    &.actived {
      color: #FD6520;
      transition: all 0.3s;
    }

    &.disabled {
      cursor: not-allowed;
      color: #cccccc;
    }

    &:hover {
      color: #FD6520;
      transition: all 0.3s;
    }

    &:not(:last-child) {
      margin-right: 32rem;
    }


    /*.menu-icon {
      transition: all 0.4s;
    }

    .menu-box {
      display: none;
      flex-direction: row;
      padding: 16rem 32rem 32rem;
      position: absolute;
      top: 64rem;
      left: 0;
      background: #fff;
      box-shadow: 0rem 3rem 10rem 0rem rgba(0, 0, 0, 0.08);

      .sub-menu {
        margin-right: 32rem;
        width: 140rem;
        font-size: 14rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #262626;
        border-bottom: 1rem solid #eeeeee;
        line-height: 60rem;

        &:last-child {
          margin-right: 0;
        }
      }

      .sub-menu-box {
        &:last-child {
          .sub-menu {
            margin-right: 0;
          }
        }

        .sub-menu {
          margin-bottom: 12rem;
        }

        .third-menu {
          font-size: 12rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #555555;
          line-height: 36rem;

          &:hover {
            color: #ff6832;
          }

          &.actived {
            color: #ff6832;
          }

          &.disabled {
            cursor: not-allowed;
            color: #cccccc;
          }
        }
      }
    }*/
  }
}
</style>
