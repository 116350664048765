<template>
    <div class="open_dev">
        <div class="banner">
            <div class="main_content_1100">
                <div class="banner_title">快麦云开放平台</div>
                <div class="banner_text">集成API快速对接 支持多端跨平台调用。</div>
                <a class="banner_btn" @click="openNew('http://admin.iot.kuaimai.com/')">立即对接</a>
            </div>
        </div>
        <div class="open_dev_main">
            <div class="main_content">
                <div class="dev_tab">
                    <div v-for="(item,index) in tabList" :key="index" class="dev_tab_item"
                         :class="{selected: tabSelected === index}" @click="()=>tabChange(index)">
                        {{ item }}
                    </div>
                </div>
                <template v-if="tabSelected === 0">
                    <div class="dev_title">云打印API介绍</div>
                    <div class="dev_api">
                        <div class="dev_sub_title">API能力</div>
                        <div class="dev_row">
                            <div class="dev_col">
                                <div class="dev_col_btn">
                                    <img class="dev_col_btn_img" src="./images/1.jpg" alt="">
                                    打印机管理
                                </div>
                            </div>
                            <div class="dev_col dev_col2">
                                <div class="dev_col_btn">
                                    <img class="dev_col_btn_img" src="./images/2.jpg" alt="">
                                    打印任务管理
                                </div>
                            </div>
                            <div class="dev_col">
                                <div class="dev_col_btn">
                                    <img class="dev_col_btn_img" src="./images/3.jpg" alt="">
                                    图片打印
                                </div>
                            </div>
                        </div>
                        <div class="dev_sub_title">对接平台支持</div>
                        <div class="dev_row dev_row2">
                            <div class="dev_col">
                                <div class="dev_col_title">PC端WEB对接</div>
                                <div class="dev_col_text">
                                    提供jssdk，调用js接口<br/>进行打印对接。
                                </div>
                            </div>
                            <div class="dev_col dev_col2">
                                <div class="dev_col_title">移动端应用对接</div>
                                <div class="dev_col_text">
                                    提供安卓端sdk，iOS端<br/>sdk对接。
                                </div>
                            </div>
                            <div class="dev_col">
                                <div class="dev_col_title">PC端应用对接</div>
                                <div class="dev_col_text">
                                    支持所有应用开发语言<br/>
                                    http协议对接；C#、C++、<br/>
                                    python等其他语言sdk开发中。
                                </div>
                            </div>
                        </div>
                        <div class="dev_sub_title">云打印API优势</div>
                        <div class="dev_row dev_row3">
                            <div class="dev_col">
                                <div class="dev_col_header">云打印功能</div>
                                <div class="dev_col_item">字号</div>
                                <div class="dev_col_item">字体</div>
                                <div class="dev_col_item">旋转打印</div>
                                <div class="dev_col_item">整图打印</div>
                                <div class="dev_col_item">模板渲染</div>
                            </div>
                            <div class="dev_col dev_col2">
                                <div class="dev_col_header">快麦云打印</div>
                                <div class="dev_col_item">支持所有字号</div>
                                <div class="dev_col_item">支持所有字体</div>
                                <div class="dev_col_item">支持90°、180°、270°所有垂直旋转</div>
                                <div class="dev_col_item">支持整图（50KB以内）下发打印</div>
                                <div class="dev_col_item">按可视化模版进行渲染，不需要了解指令</div>
                            </div>
                            <div class="dev_col">
                                <div class="dev_col_header">其他云打印</div>
                                <div class="dev_col_item">仅支持打印机内嵌字号，易出现打印锯齿问题</div>
                                <div class="dev_col_item">仅支持单一字体</div>
                                <div class="dev_col_item">不支持</div>
                                <div class="dev_col_item">不支持</div>
                                <div class="dev_col_item">需要自己拼指令或类指令xml</div>
                            </div>
                        </div>
                    </div>
                    <div class="dev_title">我们的优势</div>
                    <div class="dev_box dev_box_flex">
                        <div class="dev_box_item dev_box_item_row">
                            <img class="dev_box_img" src="./images/terminal-box-fill.svg" alt="img"/>
                            <div class="dev_box_text">
                                <div class="dev_box_text_title">高效对接</div>
                                <div class="dev_box_text_text">
                                    提供标准的http协议对接，<br/>
                                    支持多端跨平台调用；<br/>
                                    提供多种语言对接SDK。
                                </div>
                            </div>
                        </div>
                        <div class="dev_box_item dev_box_item_row">
                            <img class="dev_box_img" src="./images/device-fill.svg" alt="img"/>
                            <div class="dev_box_text">
                                <div class="dev_box_text_title">多端跨平台调用</div>
                                <div class="dev_box_text_text">
                                    不限制web、客户端、app，<br/>
                                    一端对接即可多端调用。
                                </div>
                            </div>
                        </div>
                        <div class="dev_box_item dev_box_item_row">
                            <img class="dev_box_img" src="./images/usb-fill.svg" alt="img"/>
                            <div class="dev_box_text">
                                <div class="dev_box_text_title">免费API调用</div>
                                <div class="dev_box_text_text">
                                    免费API对接和调用；<br/>
                                    免费技术支持。
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dev_title">对接指南</div>
                    <div class="dev_box">
                        <div class="dev_box_flex">
                            <div class="dev_box_item dev_box_item_col">
                                <img class="dev_box_img" src="./images/profile-fill.svg" alt="img"/>
                                <div class="dev_box_text">
                                    <div class="dev_box_text_title">注册开放平台</div>
                                    <div class="dev_box_text_text">
                                        注册成功，无需审核；<br/>
                                        72h内专属人员沟通。
                                    </div>
                                </div>
                            </div>
                            <img class="dev_arrow_img" src="../cloudPrinter/images/arrow.png" alt="arrow"/>
                            <div class="dev_box_item dev_box_item_col">
                                <img class="dev_box_img" src="./images/polaroid-2-fill.svg" alt="img"/>
                                <div class="dev_box_text">
                                    <div class="dev_box_text_title">接口开发</div>
                                    <div class="dev_box_text_text">
                                        获取对接授权后，<br/>
                                        查看接口文档并开发；<br/>
                                        建立技术支持沟通群。
                                    </div>
                                </div>
                            </div>
                            <img class="dev_arrow_img" src="../cloudPrinter/images/arrow.png" alt="arrow"/>
                            <div class="dev_box_item dev_box_item_col">
                                <img class="dev_box_img" src="./images/equalizer-fill.svg" alt="img"/>
                                <div class="dev_box_text">
                                    <div class="dev_box_text_title">接口对接调试</div>
                                    <div class="dev_box_text_text">
                                        开发完成，接口对接调试；<br/>
                                        技术支持售后问题处理。
                                    </div>
                                </div>
                            </div>
                            <img class="dev_arrow_img" src="../cloudPrinter/images/arrow.png" alt="arrow"/>
                            <div class="dev_box_item dev_box_item_col">
                                <img class="dev_box_img" src="./images/inbox-unarchive-fill.svg" alt="img"/>
                                <div class="dev_box_text">
                                    <div class="dev_box_text_title">正式上线</div>
                                    <div class="dev_box_text_text">
                                        连接打印机设备，<br/>
                                        正式上线启用。
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dev_box_btn" @click="openNew('http://admin.iot.kuaimai.com/')">立即对接</div>
                    </div>
                </template>
                <template v-else-if="tabSelected===2">
                    <div class="dev_sdk">
                        <h1 class="sdk_h1">SDK对接</h1>
                        <div class="sdk_list">
                            <div class="sdk_list_item"
                                 @click="openNew('https://github.com/xuli2016/kuaimai-cloud-demo')">
                                <img class="sdk_img" src="./images/java.png" alt="img"/>
                                <div class="sdk_text">
                                    Java版-快麦云打印SDK
                                </div>
                            </div>
                            <div class="sdk_list_item"
                                 @click="openNew('https://kuaimai-public.oss-cn-shanghai.aliyuncs.com/public/document/CloudPrint_sdk_iOS.zip')">
                                <img class="sdk_img" src="./images/iOS.png" alt="img"/>
                                <div class="sdk_text">
                                    移动端iOS-快麦云打印SDK
                                </div>
                            </div>
                            <div class="sdk_list_item"
                                 @click="openNew('https://kuaimai-public.oss-cn-shanghai.aliyuncs.com/public/document/CloudPrint_sdk_Android.zip')">
                                <img class="sdk_img" src="./images/Android.png" alt="img"/>
                                <div class="sdk_text">
                                    移动端Android-快麦云打印SDK
                                </div>
                            </div>
                        </div>
                        <!--                        <h2 class="sdk_h2">
                                                    Java版-快麦云打印SDK
                                                    <a class="link" href="https://github.com/xuli2016/kuaimai-cloud-demo"
                                                       target="_blank">点击获取</a>
                                                </h2>
                                                <h2 class="sdk_h2">
                                                    移动端iOS-快麦云打印SDK
                                                    <a class="link"
                                                       href="https://kuaimai-public.oss-cn-shanghai.aliyuncs.com/public/document/CloudPrint_sdk_iOS.zip"
                                                       target="_blank">点击获取</a>
                                                </h2>
                                                <h2 class="sdk_h2">
                                                    移动端Android-快麦云打印SDK
                                                    <a class="link"
                                                       href="https://kuaimai-public.oss-cn-shanghai.aliyuncs.com/public/document/CloudPrint_sdk_Android.zip"
                                                       target="_blank">点击获取</a>
                                                </h2>-->
                    </div>
                </template>
                <template v-else>
                    <documentIndex></documentIndex>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import documentIndex from '@/views/api-document/index.vue'

export default {
    name: "OpenDev",
    components: {documentIndex},
    data() {
        return {
            tabSelected: 0,
            tabList: ['对接指南', 'API对接', 'SDK对接']
        };
    },
    methods: {
        tabChange(index) {
            this.tabSelected = index
        },
        openNew(url) {
            window.open(url);
        },
    },
};
</script>

<style lang="scss" scoped>
.open_dev {
    .banner {
        height: 460rem;
        background: url("./images/banner.png") center / 1920rem 460rem no-repeat;

        .banner_title {
            padding-top: 214rem;
            font-size: 40rem;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: 700;
            color: #FD6520;
            line-height: 58rem;

            .banner_title_x {
                margin: 0 14rem;
            }
        }

        .banner_text {
            margin-top: 12rem;
            font-size: 24rem;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #0D0D2F;
            line-height: 35rem;
        }

        .banner_btn {
            margin-top: 33rem;
            display: block;
            width: 146rem;
            height: 50rem;
            border: 2rem solid #FD6520;
            border-radius: 50rem;
            font-size: 18rem;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #FD6520;
            line-height: 50rem;
            text-align: center;
            cursor: pointer;
        }
    }

    .open_dev_main {
        padding: 86rem 0 90rem 0;
        background: #F5F5F5;

        .dev_tab {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .dev_tab_item {
                padding: 12rem 32rem;
                background: #FFFFFF;
                border: 2rem solid #FD6520;
                font-size: 18rem;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FD6520;
                cursor: pointer;

                &:not(:first-child) {
                    border-left: 0;
                }

                &:first-child {
                    border-top-left-radius: 100rem;
                    border-bottom-left-radius: 100rem;
                }

                &:last-child {
                    border-top-right-radius: 100rem;
                    border-bottom-right-radius: 100rem;
                }

                &.selected {
                    background: #FD6520;
                    color: #FFFFFF;
                }
            }
        }

        .dev_title {
            margin-top: 30rem;
            font-size: 40rem;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 350;
            color: #FD6520;
            line-height: 58rem;
        }

        .dev_box_flex {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }

        .dev_box {
            margin-top: 20rem;
            padding: 35rem;
            background: #FFFFFF;
            border-radius: 20rem;
            border: 1rem dashed #AAAAAA;
            box-sizing: border-box;

            .dev_box_item {
                display: flex;

                &.dev_box_item_row {
                    flex-direction: row;
                    align-items: flex-start;
                }

                &.dev_box_item_col {
                    flex-direction: column;
                }

                .dev_box_img {
                    width: 40rem;
                    margin: 0 25rem 12rem 0;
                }

                .dev_box_text {
                    display: flex;
                    flex-direction: column;

                    .dev_box_text_title {
                        font-size: 18rem;
                        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                        font-weight: 700;
                        color: #FD6520;
                        line-height: 26rem;
                    }

                    .dev_box_text_text {
                        margin-top: 5rem;
                        font-size: 14rem;
                        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
                        font-weight: 350;
                        color: #191938;
                        line-height: 22rem;
                    }
                }
            }

            .dev_arrow_img {
                margin-top: 66rem;
                width: 75rem;
            }

            .dev_box_btn {
                margin: 42rem auto 0;
                width: 146rem;
                padding: 12rem 0;
                border: 2rem solid #FD6520;
                font-size: 18rem;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FD6520;
                cursor: pointer;
                text-align: center;
                border-radius: 100rem;
            }
        }

        .dev_sdk {
            padding: 64rem 0 0 0;
            text-align: center;

            .sdk_h1 {
                margin: 24rem 0 16rem 0;
                font-size: 20rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #262626;
            }

            .sdk_h2 {
                margin-top: 16rem;
                font-size: 16rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #262626;
                line-height: 40rem;
            }

            .link {
                color: #ff6932 !important;
            }

            .sdk_list {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                .sdk_list_item {
                    padding: 32rem 0;
                    cursor: pointer;

                    &:not(:last-child) {
                        margin-right: 124rem;
                    }

                    &:hover {
                        .sdk_img {
                            transform: scale(1.16);
                            transition: all 0.3s;
                        }

                        .sdk_text {
                            color: #ff6932;
                            transition: all 0.3s;
                        }
                    }

                    .sdk_img {
                        height: 90rem;
                        transform: scale(1);
                        transition: all 0.3s;
                    }

                    .sdk_text {
                        margin-top: 32rem;
                        font-size: 14rem;
                        transition: all 0.3s;
                    }
                }
            }
        }

        .dev_api {
            margin-top: 20rem;
            padding: 35rem;
            background: #ffffff;
            border-radius: 20rem;
            box-sizing: border-box;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;

            .dev_sub_title {
                margin-bottom: 24rem;
                font-size: 24rem;
                font-family: Source Han Sans CN-Normal, Source Han Sans CN;
                font-weight: 350;
                color: #FD6520;
            }

            .dev_row {
                margin-bottom: 24rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .dev_col {
                    flex: 0 0 30%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    &.dev_col2 {
                        flex: 0 0 40%;
                    }

                    .dev_col_btn {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        width: 240rem;
                        height: 80rem;
                        border: 1px dotted #FD6520;
                        color: #000;
                        font-size: 20rem;
                        font-weight: 600;
                        border-radius: 12rem;

                        .dev_col_btn_img {
                            margin-right: 16rem;
                            height: 40rem;
                        }
                    }

                    .dev_col_title {
                        width: 230rem;
                        margin-bottom: 16rem;
                        color: #FD6520;
                        font-size: 18rem;
                        font-weight: 700;
                    }

                    .dev_col_text {
                        width: 230rem;
                        color: #333;
                        font-size: 14rem;
                        line-height: 24rem;
                    }
                }
            }

            .dev_row2 {
                align-items: flex-start;

                .dev_col {
                    border-right: 1px dashed #333;
                    min-height: 110rem;
                    justify-content: flex-start;

                    &:last-child {
                        border-right: none;
                    }
                }
            }

            .dev_row3 {
                border: 1px dotted #333;
                border-radius: 16rem;

                .dev_col {
                    border-right: 1px dotted #333;

                    &:last-child {
                        border-right: none;
                    }

                    .dev_col_header {
                        border-bottom: 1px dotted #333;
                        width: 100%;
                        height: 80rem;
                        line-height: 80rem;
                        font-size: 22rem;
                        font-weight: 600;
                        text-align: center;
                    }

                    .dev_col_item {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-bottom: 1px dotted #333;
                        width: 100%;
                        line-height: 60rem;
                        font-size: 16rem;
                        text-align: center;

                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }

                .dev_col2 {
                    .dev_col_header {
                        font-size: 28rem;
                        color: #FD6520;
                    }

                    .dev_col_item {
                        color: #FD6520;
                    }
                }
            }
        }
    }
}
</style>
