<template>
    <div class="about">
        <div class="top_container main_content_1100">
            <div class="left_text">
                杭州淘云科技有限公司隶属于杭州光云科技股份有限公司（股票代码：688365），公司成立于2012年，主要产品包含快麦打印机、番茄标签、番茄打印管家、快麦云打印服务、智能全域打印解决方案。
                <br/><br/>
                作为行业领先的热敏打印解决方案提供商，快麦打印机累计热销150W+台打印机，服务客户遍布全球，产品远销美国、欧洲、东南亚等。积极与行业内的伙伴合作，推出菜鸟定制版打印机、拼多多定制版打印机，秦丝定制版打印机；并成为国内知名的快递公司如百世、圆通、韵达、极兔等供应商。
                <br/><br/>
                快麦云打印是基于快麦云打印机和快麦云软硬件为一体的解决方案；快麦云打印机联网后设备上云，SaaS软件方通过快麦API接口对接快麦云，SaaS终端用户打印时信息通过快麦云端下发给到打印机，打印数据云端保存、云端下发，保证打印不漏单。
            </div>
            <img class="top_img" src="./images/5.jpg" alt="logo"/>
        </div>
        <div class="about_bg">
            <div class="main_content_1100 about_bg_text">
                光云科技，A股电商SaaS第一股（股票代码：688365），成立于2009年，总部位于中国电商之都杭州，是互联网SaaS模式的软件技术创新型企业。作为中国首批电商SaaS服务商，光云科技秉承“让企业经营更卓越”的使命，专注于为企业提供电商运营的全链路解决方案，致力于成为全球企业软件服务领域的领跑者。
                <br/><br/>
                光云科技基于电子商务平台为电商商家提供SaaS产品，并在此基础上提供配套硬件、运营服务等增值产品及服务。围绕淘宝、天猫、京东、拼多多、抖音电商、快手电商等国内主流电商平台，光云科技已打造面向大中小型商家的电商SaaS产品体系，在线服务商家400万+，付费商家120万+。目前，旗下拥有以超级店长、快递助手、旺店系列为核心的中小商家电商SaaS产品；以快麦ERP、快麦小智、深绘、绫云、巨沃WMS、快麦智库为核心的大商家SaaS产品。
                <br/><br/>
                此外，不断拓展SaaS服务外延，借势钉钉平台，光云科技布局企业服务SaaS领域，目前已推出有成CRM、有成财务、有成报销、有成会议、有成会务、番茄表单等有成系列SaaS产品。
                <br/><br/>
                2020年4月，公司登陆科创板，股票代码：688365。
            </div>
        </div>
        <div class="about_img_box">
            <img class="about_img" src="./images/2.jpg" alt="img"/>
        </div>
        <div class="about_container main_content_1100">
            <div class="about_title">联系我们</div>
            <div class="about_list">
                <div class="about_item">
                    客服电话/QQ<br/><br/>
                    400-007-5533
                </div>
                <div class="about_item">
                    意见反馈<br/><br/>
                    beilei.fl@raycloud.com
                </div>
                <div class="about_item">
                    商务合作<br/><br/>
                    189-69120-0021
                </div>
                <div class="about_item">
                    公司地址<br/><br/>
                    浙江省杭州市滨江区江南大道588号恒鑫大厦主楼11层
                </div>
            </div>
            <img class="about_map" src="./images/3.jpg" alt="map"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "About",
    data() {
        return {};
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.about {
  padding-top: 172rem;

  .top_container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .left_text {
      flex: 1 1 auto;
      margin-right: 32rem;
      line-height: 28rem;
      font-size: 16rem;
    }

    .top_img {
      height: 410rem;
    }
  }

  .about_bg {
    margin-top: 32rem;
    height: 440rem;
    background: url("./images/1.jpg") center / 1920rem no-repeat;

    .about_bg_text {
      padding: 52rem 0;
      line-height: 28rem;
      font-size: 16rem;
    }
  }

  .about_img_box {
    margin-top: 32rem;
    text-align: center;

    .about_img {
      width: 1150rem;
    }
  }

  .about_container {
    padding: 88rem 0;

    .about_title {
      text-align: center;
      font-size: 30rem;
    }

    .about_list {
      margin: 32rem 0 16rem 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .about_item {
        width: 200rem;
        height: 80rem;
        padding: 32rem 8rem 0 8rem;
        background: #f8f9fd;
      }
    }

    .about_map {
      width: 1100rem;
    }
  }
}
</style>
