<template>
    <div class="home">
        <div class="banner">
            <el-carousel height="700rem" trigger="click" :interval="5000" arrow="never">
                <el-carousel-item>
                    <div class="banner_container">
                        <div class="main_content">
                            <div class="banner_title">快麦云标签打印机 KM-118DW</div>
                            <div class="banner_text">免下载驱动 远程打印 批量传输</div>
                            <a class="banner_btn" @click="$router.push('/productCenter/WiFi/KM118')">了解更多</a>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="home_container home_container_gray">
            <div class="main_content">
                <div class="home_title">云打印机产品</div>
                <div class="product_list">
                    <div class="product_item" @click="$router.push('/productCenter/WiFi/KM118')">
                        <div class="product_title">KM-118DW云标签打印机</div>
                        <div class="product_subtitle">服装、仓储行业标签打印</div>
                        <img class="product_img product_img1" src="./images/118D&118DW.svg" alt="product"/>
                    </div>
                    <div class="product_item">
                        <div class="product_title">KM-202MGD云面单打印机</div>
                        <div class="product_subtitle">快递、物流等快递面单打印</div>
                        <img class="product_img product_img2" src="./images/202MGD.svg" alt="product"/>
                    </div>
                    <div class="product_item" @click="$router.push('/productCenter/WiFi/DP158')">
                        <div class="product_title">DP158云小票打印机</div>
                        <div class="product_subtitle">外卖、零售等行业票据打印</div>
                        <img class="product_img product_img3" src="./images/DP158.png" alt="product"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="home_container home_container_write">
            <div class="main_content">
                <div class="home_title">快麦云打印流程</div>
                <img class="cloud_process" src="./images/cloudProcess.svg" alt="cloud_process"/>
                <a class="product_btn" @click="$router.push('/cloudPrinter')">了解更多快麦云打印流程</a>
            </div>
        </div>
        <div class="home_container home_container_gray">
            <div class="main_content">
                <div class="home_title">五大优势</div>
                <div class="advantage_list">
                    <div class="advantage_item">
                        <img class="advantage_img" src="./images/advantage1.svg" alt="advantage"/>
                        <div class="advantage_title">免驱动安装</div>
                        <div class="advantage_text">无需下载驱动，WiFi配网即用；4G插电即用。</div>
                    </div>
                    <div class="advantage_item">
                        <img class="advantage_img" src="./images/advantage2.svg" alt="advantage"/>
                        <div class="advantage_title">异地远程打印</div>
                        <div class="advantage_text">WiFi/4G入网，联网后即可实现异地、远程打印，不限打印距离。</div>
                    </div>
                    <div class="advantage_item">
                        <img class="advantage_img" src="./images/advantage3.svg" alt="advantage"/>
                        <div class="advantage_title">多人跨端打印</div>
                        <div class="advantage_text">支持多人同时打印；支持Windows、Mac、iOS 、Android等所有平台打印。</div>
                    </div>
                    <div class="advantage_item">
                        <img class="advantage_img" src="./images/advantage4.svg" alt="advantage"/>
                        <div class="advantage_title">云端防漏单</div>
                        <div class="advantage_text">
                            打印订单云端缓存，打印零漏单；打印任务自动排队，顺序打印，0错乱；断电、断网自动补打。
                        </div>
                    </div>
                    <div class="advantage_item">
                        <img class="advantage_img" src="./images/advantage5.svg" alt="advantage"/>
                        <div class="advantage_title">SaaS方低成本对接</div>
                        <div class="advantage_text">
                            支持多平台对接（Windows、Mac、iOS 、Android等）的API接口；SaaS方可视化编辑或集成模板组件的模板编辑服务。
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="home_container home_container_write">
            <div class="main_content">
                <div class="home_title">应用场景</div>
                <div class="scene_list">
                    <div class="scene_item">
                        <img class="scene_img" src="./images/scene1.svg" alt="scene">
                        <div class="scene_mask"></div>
                        <div class="scene_title">快递物流</div>
                        <div class="scene_text">快麦云面单打印机可以实现快递面单的远程、批量打印，多人共享打印。</div>
                    </div>
                    <div class="scene_item">
                        <img class="scene_img" src="./images/scene2.svg" alt="scene">
                        <div class="scene_mask"></div>
                        <div class="scene_title">仓储</div>
                        <div class="scene_text">
                            模板编辑服务支持SaaS方可视化编辑标签、小票或者集成上述编辑组件；实现SaaS方终端用户标签打印。
                        </div>
                    </div>
                    <div class="scene_item">
                        <img class="scene_img" src="./images/scene3.svg" alt="scene">
                        <div class="scene_mask"></div>
                        <div class="scene_title">服装批发</div>
                        <div class="scene_text">
                            线下服装批发档口空间有限，对打单要求高，快麦云打印服务支持多人同时打印出货单。
                        </div>
                    </div>
                    <div class="scene_item">
                        <img class="scene_img" src="./images/scene4.svg" alt="scene">
                        <div class="scene_mask"></div>
                        <div class="scene_title">商超</div>
                        <div class="scene_text">新零售时代，快麦云小票机无需手机和电脑即可打印，降低商家硬件成本。</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="home_container home_container_gray">
            <div class="main_content">
                <div class="home_title">合作伙伴</div>
                <div class="partner">
                    <img src="./images/partner.svg" alt="partner"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Home",
    data() {
        return {};
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.home {
  .banner {
    height: 700rem;

    .banner_container {
      height: 700rem;
      background: #c8f7fd url("./images/banner.png") center / 1920rem 700rem no-repeat;

      .banner_title {
        padding-top: 287rem;
        padding-left: 37rem;
        height: 58rem;
        font-size: 40rem;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #FD6520;
        line-height: 58rem;
      }

      .banner_text {
        padding-left: 37rem;
        height: 35rem;
        font-size: 24rem;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #0D0D2F;
        line-height: 35rem;
      }

      .banner_btn {
        display: block;
        margin-left: 37rem;
        margin-top: 44rem;
        width: 146rem;
        height: 50rem;
        border: 2rem solid #FD6520;
        font-size: 18rem;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #FD6520;
        line-height: 50rem;
        text-align: center;
        cursor: pointer;
        border-radius: 50rem;
      }
    }
  }

  .home_container {
    padding: 64rem 0 77rem 0;

    &.home_container_gray {
      background: #F5F5F5;
    }

    &.home_container_write {
      background: #FFFFFF;
    }

    .home_title {
      display: block;
      text-align: center;
      font-size: 30rem;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #FD6520;
      line-height: 43rem;
    }

    .product_list {
      margin-top: 39rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .product_item {
        position: relative;
        box-sizing: border-box;
        width: 380rem;
        height: 380rem;
        background: #FFFFFF;
        border: 2rem solid #FFFFFF;
        cursor: pointer;
        transition: all 0.3s;
        overflow: hidden;

        &::after {
          content: '';
          position: absolute;
          top: 2rem;
          left: 2rem;
          box-sizing: border-box;
          width: 376rem;
          height: 376rem;
          border: 8rem solid #FFFFFF;
        }

        &:hover {
          border: 2rem solid #FD6520;
          transition: all 0.3s;
        }

        .product_title {
          position: relative;
          z-index: 1;
          margin: 25rem 0 0 28rem;
          font-size: 24rem;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #262644;
          line-height: 35rem;
        }

        .product_subtitle {
          position: relative;
          z-index: 1;
          margin: 0 0 0 28rem;
          font-size: 14rem;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #FD6520;
          line-height: 20rem;
        }

        .product_img {
          position: absolute;

          &.product_img1 {
            top: 20rem;
            left: -14rem;
          }

          &.product_img2 {
            top: -36rem;
            left: -70rem;
          }

          &.product_img3 {
            top: 66rem;
            left: 64rem;
            width: 340rem;
          }
        }
      }
    }

    .cloud_process {
      display: block;
      margin: 50rem auto 65rem;
    }

    .product_btn {
      display: block;
      margin: 0 auto;
      width: 266rem;
      height: 50rem;
      text-align: center;
      border: 2rem solid #FD6520;
      font-size: 18rem;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #FD6520;
      line-height: 50rem;
      border-radius: 50rem;
      cursor: pointer;
    }

    .advantage_list {
      margin-top: 50rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .advantage_item {
        width: 220rem;
        height: 339rem;
        background: #FFFFFF;
        border-radius: 20rem;

        .advantage_img {
          display: block;
          margin: 40rem auto 0;
        }

        .advantage_title {
          margin-top: 10rem;
          font-size: 20rem;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #0D0D2F;
          line-height: 29rem;
          text-align: center;
        }

        .advantage_text {
          margin: 16rem 24rem 0;
          font-size: 16rem;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 26rem;
        }
      }
    }

    .scene_list {
      margin-top: 55rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .scene_item {
        margin-bottom: 20rem;
        position: relative;
        width: 590rem;
        height: 280rem;

        &:nth-child(3), &:nth-child(4) {
          margin-bottom: 0;
        }

        .scene_img {
          width: 590rem;
          height: 280rem;
        }

        .scene_mask {
          position: absolute;
          width: 590rem;
          height: 280rem;
          top: 0;
          left: 0;
          background: #191938;
          opacity: 0.6;
          transition: all 0.3s;
        }

        &:hover .scene_mask {
          background: #FD6520;
          transition: all 0.3s;
        }

        .scene_title {
          position: absolute;
          left: 29rem;
          top: 146rem;
          font-size: 36rem;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 28rem;
        }

        .scene_text {
          position: absolute;
          left: 29rem;
          top: 193rem;
          width: 534rem;
          font-size: 16rem;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 26rem;
        }
      }
    }

    .partner {
      margin-top: 48rem;
      text-align: center;
    }
  }
}
</style>
