<template>
    <div class="header">
        <div class="logo_container">
            <img class="logo_icon" src="./images/logo.svg" alt="logo"/>
            <hr class="logo_split"/>
            <div class="logo_text">云打印</div>
        </div>
        <div class="menus">
            <Menus></Menus>
        </div>
        <div class="right-btns">
            <a class="register" size="small" type="text" @click="openNew('http://admin.iot.kuaimai.com/')">
                注册
            </a>
            <a class="login" size="small" @click="openNew('http://admin.iot.kuaimai.com/')">登 录</a>
        </div>
    </div>
</template>

<script>
import Menus from "../Menus/Menus";

export default {
    name: "Header",
    components: {Menus},
    data() {
        return {};
    },
    mounted() {
    },
    methods: {
        openNew(url) {
            window.open(url);
        },
    },
};
</script>

<style lang="scss" scoped>
/* 顶部样式 */
.header {
  position: absolute;
  top: 46rem;
  left: 50%;
  transform: translate(-50%, 0);
  width: 1200rem;
  height: 80rem;
  background: #191938;
  box-shadow: 0 4rem 10rem 0 rgba(0, 0, 0, 0.302);
  border-radius: 50rem;
  z-index: 999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .logo_container {
    width: 330rem;
    height: 80rem;
    background: #FD6520;
    border-radius: 50rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .logo_icon {
      width: 177rem;
    }

    .logo_split {
      margin: 0 12rem;
      width: 1rem;
      height: 38rem;
      background: #FFFFFF;
      border: 0;
    }

    .logo_text {
      font-size: 22rem;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: 700;
      color: #FFFFFF;
    }
  }

  .right-btns {
    margin-right: 21rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    .register {
      margin-right: 16rem;
      font-size: 16rem;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
      cursor: pointer;
    }

    .login {
      padding: 0 16rem;
      height: 30rem;
      line-height: 30rem;
      background: #FD6520;
      font-size: 16rem;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
      border-radius: 30rem;
      cursor: pointer;
    }
  }

  .menus {
    margin: 0 38rem;
    flex: 1 1 auto;
  }
}
</style>
