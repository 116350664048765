<template>
    <div class="customer_case">
        <div class="banner">
            <div class="main_content_1100">
                <div class="banner_title">
                    秦丝
                    <img class="banner_title_x" src="./images/x.svg" alt="x"/>
                    快麦云打印服务
                </div>
                <div class="banner_text">服装档口进销存软件</div>
                <a class="banner_btn" @click="openDetail">查看详情</a>
            </div>
        </div>
        <div class="case_list">
            <div class="main_content_1100 case_list_box">
                <div class="case_item">
                    <img class="case_item_img" src="./images/case1.svg" alt="case"/>
                    <div class="case_item_text">提供进销存系统、仓库管理软件、库存管理系统、店铺管理系统等。</div>
<!--                    <div class="case_item_btn" @click="openDetail">查看详情</div>-->
                </div>
                <div class="case_item">
                    <img class="case_item_img" src="./images/case2.png" alt="case"/>
                    <div class="case_item_text">基于传统服装行业经营难、管理难等现状，推出
                        「云e宝]、「云小叮」等系列产品，覆盖服装工厂一批发一零售全链路管理。
                    </div>
                </div>
                <div class="case_item">
                    <img class="case_item_img" src="./images/case3.png" alt="case"/>
                    <div class="case_item_text">专注于家纺行业的的进销存管理软件。</div>
                </div>
                <div class="case_item">
                    <img class="case_item_img" src="./images/case4.svg" alt="case"/>
                    <div class="case_item_text">
                        睿本MOP全面助力品牌构建其渠道网络<br/>、实体连锁门店及消费者的全商户数字化赋能生态。
                    </div>
                </div>
                <div class="case_item">
                    <img class="case_item_img" src="./images/case5.svg" alt="case"/>
                    <div class="case_item_text">
                        面向用户提供旧衣回收、商城换购、慈善关爱为一体的应用；不花现金即可实现旧衣换新。
                    </div>
                </div>
                <div class="case_item">
                    <img class="case_item_img" src="./images/case6.svg" alt="case"/>
                    <div class="case_item_text">
                        海亮集团旗下生鲜农产品品牌，集科研、种植、养殖、加工、销售为一体的全产业链经营。
                    </div>
                </div>
                <div class="case_item">
                    <img class="case_item_img" src="./images/case7.svg" alt="case"/>
                    <div class="case_item_text">京东旗下同城业务品牌，为消费者提供一站式城市生活服务。</div>
                </div>
                <div class="case_item">
                    <img class="case_item_img" src="./images/case8.svg" alt="case"/>
                    <div class="case_item_text">美团旗下网上订餐平台，美团外卖用户数达2.5亿。</div>
                </div>
                <div class="case_item">
                    <img class="case_item_img" src="./images/case9.svg" alt="case"/>
                    <div class="case_item_text">本地生活平台，主营在线外卖、新零售、即时配送和餐饮供应链等业务。</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Solution",
    data() {
        return {};
    },
    methods: {
        openDetail() {
            // this.$router.push('/customerCaseDetail')
        }
    },
};
</script>

<style lang="scss" scoped>
.customer_case {
  .banner {
    height: 460rem;
    background: url("./images/banner.png") center / 1920rem 460rem no-repeat;

    .banner_title {
      padding-top: 214rem;
      font-size: 40rem;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: 700;
      color: #FD6520;
      line-height: 58rem;

      .banner_title_x {
        margin: 0 14rem;
      }
    }

    .banner_text {
      margin-top: 12rem;
      font-size: 24rem;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #0D0D2F;
      line-height: 35rem;
    }

    .banner_btn {
      margin-top: 33rem;
      display: block;
      width: 146rem;
      height: 50rem;
      border: 2rem solid #FD6520;
      border-radius: 50rem;
      font-size: 18rem;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #FD6520;
      line-height: 50rem;
      text-align: center;
      cursor: pointer;
    }
  }

  .case_list {
    padding: 86rem 0 9rem 0;
    background: #F5F5F5;

    .case_list_box {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .case_item {
        position: relative;
        margin-bottom: 67rem;
        margin-right: 25rem;
        padding: 0 30rem;
        width: 350rem;
        height: 220rem;
        background: #FFFFFF;
        border-radius: 20rem;
        opacity: 1;
        border: 1rem dashed #AAAAAA;
        box-sizing: border-box;

        &:nth-child(3n) {
          margin-right: 0;
        }

        &:hover {
          .case_item_btn {
            display: block;
          }
        }

        .case_item_img {
          margin-top: -36rem;
        }

        .case_item_text {
          margin-top: 12rem;
          font-size: 16rem;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 350;
          color: #191938;
          line-height: 23rem;
        }

        .case_item_btn {
          display: none;
          position: absolute;
          bottom: 30rem;
          left: 50%;
          transform: translate(-50%, 0);
          width: 103rem;
          height: 40rem;
          border: 2rem solid #FD6520;
          border-radius: 40rem;
          font-size: 14rem;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #FD6520;
          line-height: 40rem;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
