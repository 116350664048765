<template>
    <div class="customer_case_detail">
        <div class="banner">
            <div class="main_content_1100">
                <div class="banner_title">
                    秦丝
                    <img class="banner_title_x" src="./images/x_.svg" alt="x"/>
                    快麦云打印服务
                </div>
                <div class="banner_text">
                    深圳市秦丝科技有限公司于2014年8月成立，总部位于深圳南山区科技园，是一家专为广大中小商家提供互联网软件技术服务的科技企业。
                    <br/><br/>
                    秉承“帮助千万商户成功”的初心，成功帮助数以百万计的商家完成从手工记帐到数字化、智能化和线下线上一体化经营的管理升级。
                    <br/><br/>
                    秦丝科技旗下有适用于生产供应链的“秦丝生产供应链系统”，适用于批发/零售的“秦丝进销存”、“秦丝生意通”、“慧店宝”、“慧店通”，适用于连锁/加盟的“秦丝连锁联盟版”，还有线上订货小程序“销货宝微商城”、对接电商平台“电商宝”等增值服务，是目前国内功能完善、方案领先的商家管理应用。
                </div>
                <div class="banner_subtitle">快麦云打印设备支持</div>
                <div class="banner_list">
                    <div class="list_item">
                        <div class="list_item_title">KMQSQ5云标签打印机</div>
                        <div class="list_item_text">秦丝定制版</div>
                    </div>
                    <div class="list_item">
                        <div class="list_item_title">KMQSQ5云标签打印机</div>
                        <div class="list_item_text">秦丝定制版</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container_bg">
            <div class="main_content_1100">
                <div class="detail_main_title">场景痛点</div>
                <div class="detail_box detail_box_flex">
                    <div class="detail_box_item detail_box_item_row">
                        <img class="detail_box_img" src="../solution/images/1.svg" alt="img"/>
                        <div class="detail_box_text">
                            <div class="detail_box_text_title">客户痛点</div>
                            <div class="detail_box_text_text">
                                线下服装档口销售员出货后，需要<br/>
                                在办公室打印出货单，普通打印机<br/>
                                无法支持跨区域打印。
                            </div>
                        </div>
                    </div>
                    <div class="detail_box_item detail_box_item_row">
                        <img class="detail_box_img" src="../solution/images/2.svg" alt="img"/>
                        <div class="detail_box_text">
                            <div class="detail_box_text_title">档口空间受限</div>
                            <div class="detail_box_text_text">
                                针对电脑端的打印软件，由于档口<br/>
                                空间受限，无法配置电脑，故需要
                            </div>
                        </div>
                    </div>
                    <div class="detail_box_item detail_box_item_row">
                        <img class="detail_box_img" src="../solution/images/3.svg" alt="img"/>
                        <div class="detail_box_text">
                            <div class="detail_box_text_title">故障不能快速解决</div>
                            <div class="detail_box_text_text">
                                机器无法远程售后，线下档口打单频繁，<br/>
                                出现问题无法快速解决，效率低。
                            </div>
                        </div>
                    </div>
                </div>
                <div class="detail_main_title">解决方案</div>
                <div class="detail_box detail_box_flex detail_box_flex2">
                    <div class="detail_box_item detail_box_item_row2">
                        <img class="detail_box_img" src="./images/1.svg" alt="img"/>
                        <div class="detail_box_text">
                            <div class="detail_box_text_text">
                                秦丝档口商家线下出货，<br/>
                                线下放置QSQ5云标签打印机
                            </div>
                        </div>
                    </div>
                    <div class="detail_box_item detail_box_item_row2">
                        <img class="detail_box_img" src="./images/2.svg" alt="img"/>
                        <div class="detail_box_text">
                            <div class="detail_box_text_text">
                                办公室人员看到出货订单，<br/>
                                远程打印出货单给到线下档口
                            </div>
                        </div>
                    </div>
                    <div class="detail_box_item detail_box_item_row2">
                        <img class="detail_box_img" src="./images/3.svg" alt="img"/>
                        <div class="detail_box_text">
                            <div class="detail_box_text_text">
                                订单通过快麦云，<br/>
                                下发给云标签打印机
                            </div>
                        </div>
                    </div>
                    <div class="detail_box_item detail_box_item_row2">
                        <img class="detail_box_img" src="./images/4.svg" alt="img"/>
                        <div class="detail_box_text">
                            <div class="detail_box_text_text">
                                打印机接收打印信息，<br/>
                                打印出服装档口商家的出货单
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Detail",
    data() {
        return {};
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.customer_case_detail {
  .banner {
    height: 848rem;
    background: #F9E6CE;

    .banner_title {
      padding-top: 188rem;
      font-size: 40rem;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: 700;
      color: #191938;
      line-height: 58rem;

      .banner_title_x {
        margin: 0 14rem;
      }
    }

    .banner_text {
      margin-top: 24rem;
      width: 995rem;
      font-size: 16rem;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #3D3D3D;
      line-height: 23rem;
    }

    .banner_subtitle {
      margin-top: 58rem;
      font-size: 24rem;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: 700;
      color: #FD6520;
      line-height: 35rem;
    }

    .banner_list {
      margin-top: 32rem;
      display: flex;
      flex-direction: row;

      .list_item {
        margin-right: 80rem;
        width: 350rem;
        height: 246rem;
        border-radius: 20rem;
        border: 1rem dashed #AAAAAA;
        background: #FFFFFF url("./images/printer1.svg") 80% 0%/519rem 300rem no-repeat;

        .list_item_title {
          margin: 27rem 0 0 31rem;
          font-size: 20rem;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: 700;
          color: #262644;
          line-height: 29rem;
        }

        .list_item_text {
          margin: 5rem 0 0 31rem;
          font-size: 14rem;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 350;
          color: #666666;
          line-height: 22rem;
        }
      }
    }
  }

  .container_bg {
    background: #F5F5F5;
    padding-bottom: 140rem;

    .detail_main_title {
      padding-top: 72rem;
      font-size: 40rem;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: 700;
      color: #FD6520;
      line-height: 58rem;
    }

    .detail_box_flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      &.detail_box_flex2 {
        flex-wrap: wrap;
      }
    }

    .detail_box {
      margin-top: 20rem;
      padding: 35rem;
      background: #FFFFFF;
      border-radius: 20rem;
      border: 1rem dashed #AAAAAA;
      box-sizing: border-box;

      .detail_box_item {
        display: flex;

        &.detail_box_item_row {
          flex-direction: row;
          align-items: flex-start;
        }

        &.detail_box_item_row2 {
          margin-bottom: 56rem;
          flex-direction: row;
          align-items: center;
          flex: 0 0 50%;

          .detail_box_img {
            margin: 0 25rem 0 0;
          }
        }

        .detail_box_img {
          width: 40rem;
          margin: 0 25rem 12rem 0;
        }

        .detail_box_text {
          display: flex;
          flex-direction: column;

          .detail_box_text_title {
            font-size: 18rem;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: 700;
            color: #FD6520;
            line-height: 26rem;
          }

          .detail_box_text_text {
            margin-top: 5rem;
            font-size: 14rem;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 350;
            color: #191938;
            line-height: 22rem;
          }
        }
      }

      .detail_arrow_img {
        margin-top: 66rem;
        width: 75rem;
      }

      .detail_box_btn {
        margin: 42rem auto 0;
        width: 146rem;
        padding: 12rem 0;
        border: 2rem solid #FD6520;
        font-size: 18rem;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #FD6520;
        cursor: pointer;
        text-align: center;
        border-radius: 100rem;
      }
    }
  }
}
</style>
